@use '../../../mixin' as *;

.header {
  &__main {
    @include flex_box();
    @include flex_align_center();
    @include flex_space_between();
    height: 80px;
  }

  &__logo,
  &__menu {
    height: 100%;
  }

  .button {
    height: 100%;
  }

  &__logo {
    @include flex_box();
    @include flex_all_center();
    width: 190px;
    cursor: pointer;

    img {
      width: 150px;
    }
  }

  &__menu {
    @include flex_box();
    margin-top: 0;
    padding-right: 1rem;
    position: relative;

    .button {
      border: none;
      padding-right: 1rem;
      padding-left: 1rem;
      background: none;

      &:first-of-type {
        .button__icon {
          height: 1.3em;
        }
      }
    }
  }
}

.news_btn {
  position: relative;
}
.badge {
  height: 14px;
  width: 14px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 25px;
  left: 10px;
}

.cloud_folder_icon {
  height: 26px !important;
}
