@use '../../../mixin' as *;

.image_upload {
  background-color: transparent;
  @include flex_direction_column;
  gap: 1rem;

  &__header {
    @include flex_box();
    @include flex_align_center();

    &__text {
      flex: 1;
      font-weight: bold;
      font-size: $font_size_small;

      .img_index {
        font-size: 1rem;
        margin-left: 1rem;
      }
    }

    .button {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        height: 2.4em;
        margin-right: 1rem;
        width: 8em;
      }

      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        border: none;
        height: 1.8em;
        .button__icon {
          height: 100%;
          img {
            height: 100%;
          }
        }
      }
    }
  } // image_upload__header

  &__memo {
    p {
      font-size: $font_size_tiny;
      line-height: 1.5rem;
      font-weight: normal;
    }

    textarea {
      background-color: #fbfbfb;
      height: 80px;
    }
  } // image_upload__memo
}
