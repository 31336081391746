@use '../../mixin' as mx;
@use '../../setting' as st;

.layout,
.retouch {
  font-size: st.$font_size_small;
  $column_width: 24em;
  $category_bg_color: #383838;
  @include mx.flex_direction_column();
  height: 100%;
  background-color: #000;
  color: #fff;
  padding-bottom: 6px;

  &__header_wrap {
    background: transparent linear-gradient(180deg, #282828 0%, 55%, #4d4d4d 100%) 0% 0% no-repeat
      padding-box;
    z-index: 100;

    .layout_header,
    .retouch_header {
      $header_height: 30px;
      @include mx.flex_box();
      @include mx.flex_space_between();
      padding: 0.5em;
      color: #fff;
      border-bottom: 2px solid #686868;

      &__left,
      &__right {
        @include mx.flex_box();
        @include mx.flex_align_center();
      }

      &__logo {
        img {
          height: 2em;
          width: 2em;
        }

        margin-right: 5px;
      }

      &__menu {
        @include mx.flex_box();
        @include mx.flex_align_center();
        position: relative;
        height: 100%;
        padding: 0 5px;
        cursor: pointer;
      }
    }

    .layout_menu,
    .retouch_menu {
      @include mx.flex_box();
      @include mx.flex_align_center();
      padding: 2px 0.2em;

      > div {
        @include mx.flex_box();
        padding: 0.5em 0.75em;
        gap: 5px;
        &:nth-of-type(2) {
          @include mx.flex_content_end;
        }

        .button {
          font-weight: normal;
        }
      }

      .menu_content_pd {
        padding: 2px 8px;
      }

      .left_border_divider {
        border-left: 2px solid #686868;
      }
    }
    .retouch_menu {
      > div {
        @include mx.flex_container_wrap;
      }
    }
  }

  &__footer {
    padding: 0 6px;
    z-index: 50;

    .layout_footer {
      @include mx.flex_box();
      @include mx.flex_align_center();
      @include mx.flex_content_end;
      @include mx.flex_container_wrap;
      padding: 0.5em;
      gap: 1em;
      background-color: $category_bg_color;

      &__info {
        @include mx.flex_direction_column();
        flex: 1;
        // min-width: 500px;

        > div {
          @include mx.flex_box();
          line-height: 1.5;
          @include mx.flex_container_wrap;
          > div {
            white-space: nowrap
          }
        }

        @include mx.mq_max();
      }

      &__size {
        @include mx.flex_box();
        @include mx.flex_align_center();
        gap: 0.5em;

        > span {
          min-width: 5em;
        }

        .button {
          color: st.$layout_page_font_color;
          width: 2.4em;
          &__label {
            font-size: 1.5em;
          }

          &.btn_adjust {
            padding: 0;
            span {
              height: 100%;
            }
          }
        }
      }

      &__operation {
        @include mx.flex_box();
        gap: 0.5em;

        > .button {
          font-weight: normal;
          width: 12em;
        }
      }

      .button {
        height: 2.4em;
      }
    }
  }

  /* 横並びボタン用コンテナ */
  .switches_wrap_bg {
    @include mx.flex_box();
    @include mx.flex_align_center();
    // height: 30px;
    padding: 0.3em;
    gap: 3px;
    background-color: #2e2e2e;
    border-radius: 4px;
    // white-space: nowrap;
  }

  //  .layout, .retouch
}

/* プルダウンメニューリスト TODO 他画面でも使えそうならcommon.scss に移植 */
.pull_down_menu_list {
  position: absolute;
  top: 100%;
  left: -3px;
  background-color: #fff;
  color: #000;
  padding: 3px;
  z-index: 10;

  &__item {
    height: 2em;
    line-height: 2em;
    padding: 0 5px;
    cursor: pointer;

    &:hover,
    &.selected {
      background-color: #4b73e733;
    }
  }

  &__dimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &.child_list {
    top: 0;
    left: 100%;

    .pull_down_menu_list__item {
      padding: 0 2em;
      position: relative;
      text-align: center;

      .check_icon {
        position: absolute;
        left: 0;
        width: 2em;
        height: 100%;
        @include mx.flex_all_center;
      }
    }
  }
}
