@use "../../../mixin" as *;
@use "../../../setting" as st;

.create_order {
  $form_standard_width: 40rem;
  $form_sub_width: 560px;
  @include flex_direction_column();
  padding: 0 2rem;
  overflow-y: hidden;
  font-size: 1rem;

  &__menu {
    margin-top: 1rem;
    margin-bottom: 5px;
    > div {
      &:first-of-type {
        margin-bottom: 5px;
        .button {
          border: none;
          background: none;
        }
      }
      &:last-of-type {
        @include flex_box();
        @include flex_space_between();
        .select {
          width: auto;
        }
      }
    }
  }

  &__category {
    flex: 1;
    padding: 2rem 0.5rem 2rem 0;
    overflow-y: auto;

    .order_category {
      position: relative;
      // min-width: calc(#{$form_standard_width} + 7em);

      &:not(:first-of-type) {
        margin-top: 2rem;
      }

      &__header {
        @include flex_box;
        @include flex_align_center;
        gap: 0.5em;
        padding: 0.2em 0;

        &__tooltip {
          color: $icon_color_gray;
          font-size: 0.8em !important;
          cursor: pointer;
        }

        &__caution {
          font-size: 0.75em;
          font-weight: normal;
        }
      }

      .bottom_border_header {
        font-size: 1.25em;
      }

      &__item {
        margin-top: 0.75rem;

        .top_label_ui {
          font-size: 1em;
          &__label {
            font-size: 100%;
          }
          &__form {
            i {
              font-size: 1.875em;
            }

            .num_up_down {
              width: $form_standard_width / 4;
              border-radius: 4px;
              background-color: #fff;
              .input {
                width: 100%;
              }
              &__handle {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
              }
            }

            .checkbox {
              &__form {
                font-size: 1.5em;
              }
              &__label {
                line-height: 1.5;
              }
            }
          }
        }

        .select,
        .input,
        .item_description_place,
        .item_date_format {
          &:not(.date_picker) {
            width: $form_standard_width;
            // min-width: $form_standard_width;
          }
        }

        .select,
        .input,
        .item_description_place,
        .date_picker {
          height: 3rem !important;
        }

        .select,
        .num_up_down {
          &.disabled {
            background-color: #ddd;
          }
        }

        > .textarea {
          width: 100%;
          max-width: 63rem;
          height: 13.5rem;
        }

        .item_date_format {
          $gap: 2em;
          @include flex_box();
          gap: $gap;
          .select {
            flex: 1;
            width: calc(50% - #{$gap} / 2);
            min-width: calc(50% - #{$gap} / 2); // デフォルトのオーバーライド
          }
        }

        .item_description_place {
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          background-color: #fff;
          @include flex_box;
          @include flex_align_center;
          padding: 1em;
          gap: 1em;

          .checkbox__form {
            border-color: #e0e0e0;
            &.checked {
              border-color: #e0e0e0;
            }
          }
        }

        &.item_amount {
          .item_amount__alert {
            margin-left: 0.5em;
            color: st.$danger_color;
          }
          .num_up_down {
            .input {
              background-color: rgba(239, 192, 228, 1) !important;
            }
          }
        }
      } // order_category__item

      &__secondary {
        border: 1px solid #e0e0e0;
        padding: 1rem;
        margin-top: 1rem;
        border-radius: 2px;
      }
      // オプション
      &.option_order {
        > .flex_box {
          > div {
            flex: 1;
            .select {
              width: 100%;
              max-width: $form_standard_width;
            }
          }
        }
      }
    } // order_category

    .select_use_image_order,
    .remarks_order {
      &__caution {
        font-size: $font_size_small;
        line-height: 1.5;
        margin-top: 1rem;
      }
    }

    .select_use_image_order {
      &__caution {
        > div {
          &:nth-of-type(2) {
            text-indent: 1em;
          }
        }
      }
      &__form {
        @include flex_box();
        @include flex_align_center;
        width: $form_sub_width;
        margin-top: 1rem;
        .input {
          flex: 1;
          margin-right: 0.5rem;
          height: 3em;
        }
        .button {
          width: 80px;
        }
      }
    }

    .remarks_order {
      &__caution {
        > div {
          &:nth-of-type(3) {
            text-indent: 1em;
          }
        }
      }
    }

    .helped_btn {
      margin-top: 1rem;
    }
  }
}

.order_help {
  &.button {
    font-size: inherit;
    height: max-content;
    background-color: transparent;
    padding: 0;
    border-radius: 50%;
    .button__icon {
      height: 1.875em;
    }
  }
}
