@use '../../../mixin.scss' as *;
@use '../../../_setting.scss' as st;

.thumbnail {
  user-select: none;
  width: 10rem;
  position: relative;

  &__thumb {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #cccccc50;

    &::before {
      content: '';
      display: block;
      padding-top: calc(100% / 3 * 2);
    }

    &.shadow {
      box-shadow: 0 0 2px 0 rgba($color: #000000, $alpha: 0.2);
    }
    > * {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    img {
      margin: auto;
    }

    &__btn {
      width: 4em;
      height: 2em !important;
      position: absolute;
      top: initial;
      left: initial;
      bottom: 0.5em;
      right: 0.5em;
      background-color: #ffffffcc !important;
      color: #000 !important;
      font-weight: normal;
      animation-name: fade_in;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }
  } // thumbnail__thumb

  &__label {
    @include flex_box;
    @include flex_align_center;
    color: black;
    padding-top: 0.5em;
    font-size: 0.7rem;
    line-height: 1.5em;
    text-align: left;

    > span {
      flex: 1;
      @include ellipsis();
    }

    &.countable {
      gap: 0 0.25em;
      .bordered_left_label_item {
        min-width: 8em;
        width: 8em;
        height: 2em;

        > * {
          line-height: 2em;
        }
        > span {
          color: #fff;
        }
        > .num_up_down {
          padding: 0;
          min-width: auto;
          width: 100%;
          border: none;
          .input {
            height: 100%;
          }
        }
      }
    }
  }

  &__wrap {
    &.selected {
      position: relative;
      padding: 0.675em 1em;
      @include flex_all_center;
      
      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        border: 2px solid st.$primary_color;
        border-radius: 4px;
        position: absolute;
        top: -2px;
        left: 0;
        background-color: rgba($color: st.$primary_color, $alpha: 0.05);
      }
    }
  }

  &.alert {
    .thumbnail__label {
      color: st.$danger_color;
    }
  }

  @keyframes fade_in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
} // thumbnail
