@use '../../../mixin' as mx;
@use '../../../setting' as *;

.type_select_button {
  @include mx.flex_all_center;
  font-size: $font_size_small;
  padding: 1px 1em;
  height: 2em;
  cursor: pointer;
  white-space: nowrap;

  &.current {
    background-color: $primary_color;
    color: #fff;
    font-weight: bold;
    border-radius: 1em;
  }
}

.shop_list_table {
  @include mx.flex_direction_column;
  flex: 1;
  overflow: hidden;

  &__type_select {
    overflow-x: auto;
    padding-bottom: 2px;
    @include mx.scrollbar_no_track;

    > div {
      @include mx.flex_box;
      @include mx.flex_align_center;
      // min-width: 855px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      .button {
        background: none;
        color: $primary_color;
        height: 24px;
        font-weight: normal;
      }
    }
  }

  &__menu_btn {
    @include mx.flex_box;
    justify-content: flex-end;
    margin-top: 0.5rem;

    .input {
      height: 100%;
    }

    .button {
      padding: 1px 1rem;
      margin-left: 0.5rem;

      .button__icon {
        height: 1.3em;
      }
    }

    .proxy_mode_form {
      font-size: 1rem;
      font-weight: bold;
      @include mx.flex_box;
      gap: 0 0.5em;

      &__label {
        > div {
          &:last-of-type {
            font-size: 0.75em;
          }
        }
      }

      .input {
        width: 10em;
        height: 2.2rem;
      }
    }
  }

  // order_table__menu_btn

  &__table {
    flex: 1;
    margin-top: 0.5rem;
    overflow: hidden;

    .table {
      padding-right: 1px; // スクロールバーとの間隔調整用
      //&__head_0 {
      //  width: 5rem;
      //  min-width: 5rem;
      //}

      &__head_1 {
        //width: 27%;
        min-width: 20rem;
      }

      &__head_0,
      &__head_2,
      &__head_3,
      &__head_4,
      &__head_5,
      &__head_5,
      &__head_6,
      &__head_7,
      &__head_8,
      &__head_9,
      &__head_10,
      &__head_11,
      &__head_12,
      &__head_13,
      &__head_14,
      &__head_15,
      &__head_16,
      &__head_17 {
        width: 10%;
        min-width: 8rem;
      }

      &__head_18 {
        position: sticky;
        right: -1px;
        background-color: $primary_color;
        width: 10%;
        min-width: 6rem;
      }

      &__data_0,
      &__data_2,
      &__data_3,
      &__data_4 {
        text-align: center;
      }

      &__data_5,
      &__data_6,
      &__data_7,
      &__data_8,
      &__data_9,
      &__data_10,
      &__data_11,
      &__data_12,
      &__data_13,
      &__data_14,
      &__data_15,
      &__data_16,
      &__data_17 {
        text-align: right;
      }

      &__data_18 {
        position: sticky;
        right: -1px;
        padding: 0 !important;

        div {
          height: 100%;

          .button {
            margin: auto;
          }
        }
      }
    }
  }

  .table {
    table {
      //width: 100%;
      width: fit-content;
      font-size: $font_size_small;
      background-color: #fff;
      border-collapse: collapse;

      th,
      td {
        white-space: nowrap;
        padding: 5px 10px;
      }

      .checkbox {
        &__form {
          background-color: #fff;

          &.checked {
            border-color: currentColor;
          }
        }
      }

      thead {
        height: 3em;
        position: sticky;
        top: 0;
        z-index: 1;

        th {
          background-color: $ui_header_color;
          color: #fff;
          font-weight: normal;
          text-align: center;

          //position: relative;

          &::before {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            border-left: 1px solid #fff;
            position: absolute;
            top: 0;
            left: -1px;
          }
        }
      }
    }
  }
}
