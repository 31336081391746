@use "../../../setting" as *;
@use "../../../mixin" as mx;

.contact_sheet {
  @include mx.flex_direction_column;
  @include mx.flex_align_center;
  height: 100%;
  overflow: hidden;

  &__body {
    width: 100%;
    height: 100%;
    padding: 1rem;
    border: 1px solid $border_gray_light;
    border-radius: 2px;
    margin: auto;
    @include mx.flex_direction_column;
    gap: 1em 0;
    overflow: hidden;

    &__outer {
      flex: 1;
      width: 100%;
      padding: 1rem;
      border: 1px solid $border_gray_normal;
      border-radius: 2px;
      overflow-y: auto;
      @include mx.scrollbar_no_track;
      &.preview {
        overflow-y: hidden;
        overflow-x: auto;
        .contact_sheet__body__wrap {
          transform-origin: center top;
        }
      }
    }

    &__wrap {
      margin: auto;
    }

    &__info {
      @include mx.flex_direction_column;
      width: 100%;
      min-height: 7rem;
      height: 7rem;
      background-color: #e0e0e0;
      padding: 10px;

      > div {
        @include mx.flex_box;
        @include mx.flex_align_center;
        &:first-of-type {
          @include mx.flex_content_center;
          flex: 2;
          font-size: 18px;
          font-weight: bold;
        }
        &:not(:first-of-type) {
          flex: 1;
          font-size: 12px;
        }
        &:nth-of-type(2) {
          > div {
            width: 50%;
            &:first-of-type {
              text-align: left;
            }
            &:last-of-type {
              text-align: right;
            }
          }
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
          gap: 0.5em;
          > div {
            &:first-of-type {
              min-width: 10.75em;
            }
            &:nth-of-type(2) {
              @include mx.ellipsis;
              min-width: 19em;
            }
          }
        }
        &:nth-of-type(3) {
          > div {
            &:nth-of-type(3) {
              min-width: 17.5em;
            }
          }
        }
        &:nth-of-type(4) {
          > div {
            &:nth-of-type(3) {
              min-width: 10em;
            }
            &:nth-of-type(4) {
              flex: 1;
              text-align: right;
            }
          }
        }
      }
    } // contact_sheet__body__info

    &__images {
      @include mx.flex_box;
      @include mx.flex_container_wrap;
      gap: 8px 16px;

      &__item {
        width: calc(100% / 4 - 12px);
        .thumbnail {
          width: 100%;
          &__label {
            font-size: 10px;
            text-align: center;
            height: 3.5em;
            @include mx.flex_align_start;
            > span {
              white-space: normal;
              word-break: break-all;
              padding: 0 8px;
            }
          }
        }
      }
    } // contact_sheet__body__images
  } // contact_sheet__body

  &__footer {
    @include mx.flex_all_center;
    padding: 1rem 0;
    gap: 30px;

    &__current_page {
      font-size: 14px;
      .input {
        display: inline-block;
        height: 24px;
        width: 3em;
        line-height: 1.5;
        border: 1px solid $border_gray_normal;
        border-radius: 0;
        min-width: 0;
        input {
          text-align: center;
        }
      }
    }
  } // contact_sheet__footer
}
