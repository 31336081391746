@use '../../../mixin' as mx;

.base_image {
  width: 100%;
  height: 100%;
  @include mx.flex_box;
  @include mx.flex_all_center;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__dummy {
     position: absolute;
     opacity: 0.01;
  }
}
