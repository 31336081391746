@use '../../../mixin' as *;

.login {
  height: 100%;
  overflow: auto;

  &__inner {
    height: 100%;
    // min-height: 768px;
    @include flex_box();
  }

  &__col1 {
    @include flex_all_center();
    position: relative;
    min-width: 53vw;
    max-width: 53vw;
    background-color: rgb(70, 70, 70);

    img {
      user-select: none;
      pointer-events: none;

      &:first-of-type {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &:last-of-type {
        position: absolute;
        width: 36.7%;
      }
    }
  }
  .switch_login {
    position: absolute;
    bottom: 13%;
    button {
      padding: 0 5rem;
    }
  }

  .what_is_staff_management {
    position: absolute;
    text-align: center;
    font-size: 0.9rem;
    width: 100%;
    bottom: 9.5%;
    a{
      color : #1EAFFF;
    }
    img {
      width: 1rem;
      height: 1rem;
      vertical-align: text-bottom;
      margin-right: 0.2rem;
      transform: translateX(-1.1rem);
    }
  }

  .copywriter {
    position: absolute;
    text-align: center;
    width: 100%;
    color: white;
    bottom: 5%;
  }
  .version {
    position: absolute;
    opacity: 0.5;
    bottom: 2%;
    left: 2%;

    &__admin {
      color: #ffffff;
    }

    &__staff {
      color: #000000;
    }
  }

  &__col2 {
    font-size: clamp(12px, 2vw, 16px);
    @include flex_all_center();
    flex: 1;
    padding: clamp(1rem, 2.5vw, 3rem);

    &__inner {
      height: 413px;
      width: 100%;
      max-width: 28.4375rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &__header {
        font-size: 1.5em;
      }

      &__contents {
        margin-top: 1rem;
        padding: clamp(1rem, 2.5vw, 3rem);
        background-color: #fff;

        div + div {
          margin-top: 1rem;
        }

      }

      .machine-id {
        display: flex;
        align-content: flex-end;

        &__help {
          color: #d70b24;
          width: 140px;
          height: fit-content;
          font-size: 14px;
          margin: 2rem 0 0 5px;
          cursor: pointer;
        }
      }

      .input {
        width: 100%;
      }

      .button {
        font-size: 100%;
        width: 100%;
        margin-top: 2rem;
      }
    }
  }

  &__pass_display {
    @include flex_box;
    @include flex_align_center;
    gap: 0.5em;
    cursor: pointer;
    margin-top: 0.75em !important;

    img {
      width: 1.8em;
    }

    // .login__pass_display
  }

  &__pass_remember {
    display: block;
    text-decoration: none;
    margin-top: 1em;
    color: $primary_color;
    cursor: pointer;

    // .login__pass_display
  }

  .chrome__guide {
    padding: 20px;
    margin-top: 20px;
    background-color: rgb(206, 227, 253);

    &__link {
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      //background-color: red;
      margin-right: 10px;
      font-size: 14px;
    }
  }
}
