@use '../../../../mixin' as mx;
@use '../../../../setting' as *;

.preview_sidebar {
  // font-size: 0.75rem;
  @include mx.flex_direction_column();
  background-color: $category_bg_color;
  padding: 6px 0;
  z-index: 50;
  gap: 6px;

  .bordered_left_label_item {
    height: 2em;
    > * {
      line-height: 2em;
    }
  }

  /* 縮小時 */
  &.close {
    &:not(.full_width) {
      flex: none;
      width: auto;
      min-width: auto;
      height: auto !important;
      background-color: transparent;
      padding: 0;
      .preview_sidebar__header {
        padding: 0;
        .open_btn {
          background-color: $layout_page_category_bg_color;
          padding: 6px;
          height: auto;
          gap: 0;
        }
      }
    }
  }

  &__header {
    @include mx.flex_box();
    gap: 6px;
    padding: 0 6px;

    &__info {
      @include mx.flex_box;
      flex: 1;
      gap: 3px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .open_btn {
      @include mx.flex_box();
      @include mx.flex_align_start;
      gap: 5px;
      cursor: pointer;

      .button {
        height: 1.5em;
        width: 1.5em;
        padding: 0;

        &__icon {
          position: relative;
          height: initial;

          img {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 1px;
            margin: auto;
            width: 100%;
          }
        }

        &.open {
          img {
            right: 1px;
            left: 0;
          }
        }
      }
    }
  }

  &__list {
    @include mx.flex_direction_column();
    overflow-y: auto;
    padding: 0 6px 6px;
    gap: 0.5em;

    /* プレビューコンテンツ用アイテム */
    .layout_preview_item {
      width: 100%;
      position: relative;

      &::before {
        display: block;
        content: '';
        padding-top: 65.5%;
      }

      &__inner {
        @include mx.flex_direction_column();
        width: 100%;
        height: 100%;
        padding: 0.4em;
        gap: 0.25em;
        position: absolute;
        top: 0;
        left: 0;
      }

      &__wrap {
        min-width: initial !important;
        width: 100%;
        border: 1px solid $layout_item_border_color;
        position: relative;
        cursor: pointer;

        &:focus {
          border-color: $layout_active_color;
          @include mx.box_shadow($y: 0, $blur: 0.5em, $color: $layout_active_color);
        }
      }

      &__header {
        @include mx.flex_box();
        // @include mx.flex_container_wrap;
        gap: 0.25em;

        .bordered_left_label_item {
          &:not(:first-of-type) {
            flex: 1;
            span {
              padding: 0 0.5em;
            }
          }
          &:first-of-type {
            width: 5em;
            span {
              width: 100%;
              padding: 0;
            }
          }
        }
      }

      &__body {
        flex: 1;
        position: relative;
        width: 100%;
        @include mx.flex_all_center;
        overflow: hidden;
        // background-color: #eeeeee20;

        img {
          height: 100% !important;
          width: 100% !important;
          -o-object-fit: contain;
          object-fit: contain;
        }

        &__delete {
          @include mx.flex_all_center;
          width: 2.5em;
          height: 2.5em;
          border-radius: 50%;
          background-color: rgba($color: #000, $alpha: 0.5);
          position: absolute;
          top: 2px;
          right: 0;
          &:hover {
            background-color: rgba(149, 149, 149, 0.8) ;
          }


          &::before {
            display: block;
            content: '\0d7';
            font-size: 2.1em;
            position: absolute;
            top: 0;
            bottom: 0;
            line-height: 1.1;
          }
        }
      }

      &__dummy {
        font-size: 1.75em;
        width: 100%;
        height: 100%;
        @include mx.flex_all_center;
        -webkit-flex-direction: column;
        flex-direction: column;
        -ms-flex-direction: column;
        background-color: #606060;
        gap: 0.25em 0;
        font-weight: bold;
        &__page {
          @include mx.flex_box;
          @include mx.flex_align_center;
          gap: 0 0.125em;
          .plus_icon {
            font-size: 0.8em;
            width: 1em;
            height: 1em;
            transform: translateY(1px) rotateZ(45deg);
            &::before,
            &::after {
              height: 0.15em;
            }
          }
          &::before {
            // display: inline-block;
            // content: '\FF0B';
            // transform: rotate(45deg);
          }
        }
      }
      &__warning-icon-tooltip {
        color: $danger_color;
        font-weight: bold;
        cursor: default;
      }
    }
  }

  /* レイアウト一覧画面表示時 */
  &.full_width {
    width: 100% !important;
    padding: 0.75em !important;

    .preview_sidebar__list {
      width: 100%;
      // display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(26em, 1fr));
      gap: 10px;

      @include mx.flex_box;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      @include mx.flex_container_wrap;
      .layout_preview_item__wrap {
        width: 26em;
      }
    }

    .layout_preview_item {
      // min-width: 26em;
      width: 100%;
    }
  }

  // .preview_sidebar
}
