@use '../../../setting' as *;
@use '../../../mixin' as mx;

.num_up_down {
  @include mx.flex_box();
  min-width: 80px;
  width: 320px;
  border: 1px solid $form_border_color;
  color: currentColor !important;
  position: relative;

  .input {
    width: 100%;
    min-width: 0 !important;
    border: none !important;
    color: #000;
    border-radius: 0;
    &.disabled {
      background-color: transparent;
    }
    &__form {
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    background-color: #4b73e71a;
  }

  &__handle {
    width: 1.2em;
    @include mx.flex_direction_column();
    @include mx.flex_align_center;
    cursor: pointer;

    .angle_icon {
      font-size: calc(1em - 2px);
      flex: 1;
      min-width: 1em;

      &.top {
        &:before {
          bottom: 0.25em;
        }
      }

      &.bottom {
        &:before {
          top: 0.25em;
        }
      }
    }
  }
}
