@use '../../../mixin' as *;

.table {
  height: 100%;
  overflow-y: auto;

  @include scrollbar_no_track;

  table {
    width: 100%;
    font-size: $font_size_small;
    background-color: #fff;
    border-collapse: collapse;

    th,
    td {
      white-space: nowrap;
      padding: 5px 10px;
    }

    .checkbox {
      &__form {
        background-color: #fff;
        &.checked {
          border-color: currentColor;
        }
      }
    }

    thead {
      height: 3em;
      position: sticky;
      top: 0;
      z-index: 1;

      th {
        background-color: $ui_header_color;
        color: #fff;
        font-weight: normal;
        text-align: center;

        + th {
          position: relative;

          &::before {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            border-left: 1px solid #fff;
            position: absolute;
            top: 0;
            left: -1px;
          }
        }
      }
    }

    tbody {
      tr {
        cursor: default;
        height: 3em;
        user-select: none;

        &:nth-of-type(2n) {
          background-color: $table_td_even_color;
        }

        &:nth-of-type(2n + 1) {
          background-color: $table_td_odd_color;
        }

        &.selected_row {
          background-color: #e4e9fa;
        }

        &.clickable {
          cursor: pointer;
        }

        td {
          @include ellipsis();
          width: 1px;
          /* 必要であれば使用箇所で適宜 width, max-width を指定すること */

          &.insert_data {
            background-color: #fff;
            padding: 1rem 14px;
            border-bottom: 1px solid #e4e9fa;
          }

        }
      }
    }
  }
}
