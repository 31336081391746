@use '../../../mixin' as mx;
@use '../../../setting' as *;

.type_select_button {
  @include mx.flex_all_center;
  font-size: $font_size_small;
  padding: 1px 1em;
  height: 2em;
  cursor: pointer;
  white-space: nowrap;

  &.current {
    background-color: $primary_color;
    color: #fff;
    font-weight: bold;
    border-radius: 1em;
  }
}

.staff_return {
  .button.light {
    background-color: #fbfbfb;
    justify-content: flex-start;
    padding-left: 2rem;
  }
}

.staff_table {
  @include mx.flex_direction_column;
  flex: 1;
  margin-top: 1rem;
  overflow: hidden;
  &__menu {
    @include mx.flex_box;
    @include mx.flex_align_center;
    gap: 1rem;

    .button {
      padding: 1px 1rem;
    }
    &__checkbox {
      @include mx.flex_box;
      margin-left: auto;
      font-size: $font_size_small;

    }
    // order_table__menu_btn
    &__search {
      @include mx.flex_box;
      @include mx.flex_align_center;
      background: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 2px;
      height: 2.5em;
      width: 15em;

      i {
        font-size: 1.1em;
        color: $primary_color;
        padding: 0 5px
      }

      input {
        border: none;
      }
    }
  }


  &__table {
    flex: 1;
    margin-top: 0.5rem;
    overflow: hidden;

    .table {
      padding-right: 1px; // スクロールバーとの間隔調整用

      thead{
        th{
          background-color: $staff_table_thead_background_color;
        }
      }

      tbody{
        tr {
          &:nth-of-type(2n) {
            background-color: $staff_table_td_odd_color;
          }
          &:nth-of-type(2n + 1) {
            background-color: $staff_table_td_even_color;
          }
        }
      }

      &__head_0 {
        width: 10rem;
        min-width: 10rem;
      }
      &__head_1 {
        width: 7.5rem;
        min-width: 7.5rem;
      }
      &__head_2 {
        width: 7rem;
        min-width: 7rem;
      }
      &__head_3 {
        width: 10.625rem;
        min-width: 10.625rem;
      }
      &__head_4 {
        width: 10.625rem;
        min-width: 10.625rem;
      }
      &__head_5 {
        width: 7.5rem;
        min-width: 7.5rem;
      }
      &__head_6 {
        width: 9.5rem;
        min-width: 9.5rem;
      }
      &__data_0 {
        max-width: 10.625rem;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &__data_1 {
        max-width: 7.5rem;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &__data_2 {
        text-align: center;
      }
      &__data_3 {
        text-align: center;
      }
      &__data_4 {
        max-width: 10.625rem;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &__data_5 {
        text-align: center;
      }
      &__data_6 {
        text-align: center;
      }
      &__data_7 {
        text-align: center;
        img {
          display: inline-block;
          width: 1.5em;
          height: 1.5em;
        }
        > div {
          @include mx.flex_box;
          @include mx.flex_align_center;
          justify-content: center;
          gap: 0 0.25em;

          > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      &__data_8 {
        > div {
          @include mx.flex_box;
          @include mx.flex_align_center;
          justify-content: center;
        }
      }
    }
  }
}
