@use '../../../setting' as *;

$radio_style_border_color: #707070;
$radio_style_size: 18px;
$radio_style_checked_size: 10px;
$radio_style_ripple_size: 10px;

.radio {
  input[type='radio'] {
    display: none;
  }
  label {
    display: inline-block;
    min-height: $radio_style_size;
    position: relative;
    padding-left: $radio_style_size + 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    white-space: nowrap;
    line-height: 20px;
    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      // transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: $radio_style_size;
      height: $radio_style_size;
      border: 1px solid $radio_style_border_color;
    }
    &:after {
      top: $radio_style_size / 2 - $radio_style_checked_size / 2;
      left: $radio_style_size / 2 - $radio_style_checked_size / 2;
      width: $radio_style_checked_size;
      height: $radio_style_checked_size;
      transform: scale(0);
      background: #5f6368;
    }
    &.checked::before {
      // animation: ripple 0.2s linear forwards;
    }
    &.checked::after {
      transform: scale(1);
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}

// @keyframes ripple {
//   0% {
//     box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
//   }
//   50% {
//     box-shadow: 0px 0px 0px $radio_style_ripple_size rgba(0, 0, 0, 0.1);
//   }
//   100% {
//     box-shadow: 0px 0px 0px $radio_style_ripple_size rgba(0, 0, 0, 0);
//   }
// }
