@use '/src/mixin' as mixin;

.retouch_menu {
  //@include mixin.flex_space_between();
  
  &__display {
    @include mixin.flex_box();
    @include mixin.flex_align_center();
    gap: 5px;
    margin-left: auto;
  }
}