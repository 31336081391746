@use "../../../mixin" as mx;

.save_favorite_brand {
  max-height: initial !important;
  &__ancestor {
    min-height: 44rem !important;
  }
  > .dialog_contents__body {
    @include mx.scroll_display_none;
    gap: 1em 0;
    @include mx.flex_direction_column();
    max-height: initial !important;
  }
  &__top {
    @include mx.flex_direction_column();
    span {
      &:nth-of-type(2) {
        margin-top: 1em;
      }
      &:nth-of-type(3) {
        margin-top: 0.5em;
      }
    }

    .input {
      margin-top: 0.5em;
      width: 100%;
    }
  }

  &__bottom {
    @include mx.flex_direction_column();
    overflow: hidden;
    > span {
      line-height: 2;
    }

    .favorite_brand_config {
      flex: 1;
      overflow: hidden;
      height: auto;
    }
  }
}
