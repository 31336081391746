@use '../../../mixin' as *;

.file_drop_zone {
  &__contents {
  } // file_drop_zone__contents

  &__error {
    color: $danger_color;
    // margin-bottom: 0.75rem;
    // .file_drop_zone__error
  }

  &__drop {
    @include flex_direction_column();
    @include flex_align_center();
    padding: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
    outline: 'none';
    transition: background-color 0.24s ease-in-out;
    gap: 1rem;
    color: #888888;
    position: relative;

    &__wrap {
      padding: 1rem;
    }

    span {
      cursor: default;
      user-select: none;
    }

    label {
      padding: 1px 1.5rem !important;
      cursor: pointer;
      input {
        display: none;
      }
    }

    /* ドロップ可能な場合 */
    &.drag_accept {
      background-color: #dcebf5;
    }

    /* ドロップ拒否の場合 */
    &.drag_reject {
      background-color: #ffe4e1;
    }

    &__buttons {
      display: flex;
      align-items: center;
      .button {
        margin: 0 10px;
      }
    }
  } // .file_drop_zone__drop

  &__list {
    font-weight: normal;
    &__sort {
      @include flex_direction_column;
      @include flex_align_center;
      gap: 1em;
      margin: 1em 0 2em;
      &__menu {
        @include flex_box;
        gap: 4em;
        color: #888888;

        > div {
          cursor: pointer;
          &.selected {
            color: $primary_color;
          }
        }
      }

      &__guidance {
        color: $danger_color;
      }
    }

    &__thumbs {
      width: 100%;
      @include grid;
      @include grid_repeat_fill($minCol: 16em);
      overflow: hidden;
      overflow-y: auto;
      padding: 2px 1rem; // サムネ選択時に適用される2pxのボーダーが隠れないようにする
      @include scrollbar_no_track;
      .thumbnail {
        width: 100%;
      }
    }
  } // .file_drop_zone__list
}
