@charset "UTF-8";
@use 'src/setting' as st;
@use 'src/mixin' as mx;

/*共通設定*/
input,
textarea {
  @include mx.border-default($color: st.$form_border_color);

  &::placeholder {
    color: st.$placeholder_color;
  }
}

textarea {
  resize: none;
  width: 100%;
  padding: 10px;
}

input[type='file'] {
  border: none !important;
}

.left_label_form {
  @include mx.flex_box();
  @include mx.flex_align_center();
  height: 30px;
  gap: 0.3em;

  span {
    &:not(.form_unit) {
      @include mx.flex_all_center();
      height: 100%;
      width: 5em;
      min-width: 5em;
      background-color: #606060;
    }
  }

  .input,
  .num_up_down,
  .select,
  .textarea {
    height: 100% !important;
    flex: 1;
    border: 1px solid st.$layout_item_border_color;
    border-radius: 0;
    background-color: transparent;
    color: st.$text_color;
    min-width: 55px;
  }

  .num_up_down {
    .angle_icon {
      color: currentColor !important;
    }
  }
}

/* 上部ラベル付きUI */
.top_label_ui {
  @include mx.flex_direction_column();

  &__label {
    font-size: st.$font_size_tiny;
    height: 2em;
    line-height: 2;

    &__required {
      margin-left: 0.5em;

      &:before {
        content: '\203B';
        color: st.$danger_color;
        font-size: 1em;
        font-weight: 100;
      }
    }
  }

  &__form {
    @include mx.flex_box;
    @include mx.flex_align_center;
    gap: 0.5em;

    i {
      font-size: 1.5em;
      color: st.$icon_color_gray;
      cursor: pointer;
    }
  }
}

/* 郵便番号フォーム */
.postal_form {
  @include mx.flex_box();
  @include mx.flex_align_center();

  .input {
    &:first-of-type {
      min-width: 4.5em;
      width: 4.5em;
    }

    &:last-of-type {
      min-width: 5em;
      width: 5em;
    }
  }

  &__hyphen {
    &:before {
      content: '-';
      margin: 0 5px;
    }
  }

  &__search_btn {
    margin-left: 10px;
  }
}

/* 開始と終了あり日付入力 */
.date_start_end {
  @include mx.flex_box();
  @include mx.flex_align_center();
  gap: 0 10px;
  .input {
    flex: 1;
    width: auto;
  }

  &__tilde {
    height: 1em;
    line-height: 1em;
    &::before {
      display: block;
      content: '\301C';
    }
  }
}

.radio_buttons_box {
  @include mx.flex_box;
  gap: 0 0.8em;
}

.helped_btn {
  width: max-content;
  @include mx.flex_box;
  @include mx.flex_align_center;
  gap: 0.5em;
}