.dom_content_tooltip {
  $center_offset: -50%;
  $top_offset: -100%;
  $bottom_offset: 0;
  max-width: none;
  overflow: hidden;
  animation-delay: 0.5s;

  &.pos_center_center {
    transform: translate($center_offset, $center_offset);
    &.edge_right {
      transform: translate(0, $center_offset);
    }
  }
  &.pos_center_top {
    transform: translate($center_offset, $top_offset);
    &.edge_right {
      transform: translate(0, $top_offset);
    }
  }
  &.pos_center_bottom {
    transform: translate($center_offset, $bottom_offset);
    &.edge_right {
      transform: translate(0, $bottom_offset);
    }
  }
  &.edge_right {
    left: initial !important;
    right: 1rem !important;
  }
}
