@use '../../../../mixin' as mx;
@use '../../../../setting' as *;

.layout_item {
  @include mx.flex_direction_column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $layout_item_outer_pd;
  gap: 4px;
  user-select: none;

  &__wrap {
    width: 100%;
    border: 2px solid $layout_item_border_color;
    position: relative;
    cursor: pointer;

    &:before {
      display: block;
      content: '';
      padding-top: 74.8%;
    }
  }
  &__warning_icon {
    cursor: default;
    z-index: 1;
    img {
      height: 100% !important;
      width: 100% !important;
      -o-object-fit: contain;
      object-fit: contain;
      user-drag: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
    }
    .tooltip {
      z-index: 2;
    }
    &__tooltip {
      color: $danger_color;
      font-weight: bold;
      cursor: default;
    }
  }

  &__thumb {
    flex: 1;
    background-color: #808080;
    overflow: hidden;
    @include mx.flex_all_center;

    img {
      // width: 100%;
      // height: 100%;
      // object-fit: contain;
    }

    .loading_popup__dots {
      position: absolute;
    }
    // layout_item__thumb
  }

  &__info {
    @include mx.flex_box;
    @include mx.flex_align_center;
    width: 100%;
    height: 40px;
    padding: 0 6px;
    gap: 6px;

    &__tag {
      min-width: 60px;
      width: 60px;
      height: 24px;
      background-color: #fff;
      color: #000;
      font-size: $font_size_small;
      line-height: 24px;
      text-align: center;
    }

    &__detail {
      line-height: 1.4;
      @include mx.ellipsis_nth_clamp(2);
    }
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.4);
    @include mx.flex_all_center;

    i {
      font-size: 5em;
    }
    // .layout_item__cover
  }
}

.layout_select_template {
  width: 100%;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  padding: 12px 10px;
  gap: 12px;

  @include mx.flex_box;
  @include mx.flex_container_wrap;
  .layout_item__wrap{
    width: 18rem;
  }
}
