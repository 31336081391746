@use '../../../mixin' as mx;

.preset_list {
  .table {
    &__data_0 {
      width: 50px;
      text-align: right;
    }
    &__data_1 {
      width: 50px;
      text-align: right;
    }

    &__data_2 {
      width: 264px;
      max-width: 264px;
    }

    &__data_3,
    &__data_4,
    &__data_5,
    &__data_6 {
      width: 80px;
      text-align: center;
    }

    &__data_7 {
      width: 68px;
    }
  }

  .dialog_contents__footer {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
}