@use '../../setting' as *;

.tool_bar {
  font-size: $font_size_tiny;
  background-color: #ffffff;
  padding: 0.3em;
  position: absolute;

  &__blur_bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000000;
  }

  &__item {
    padding: 0 0.5em;
    line-height: 2em;
    cursor: pointer;

    &:hover {
      background-color: #4b73e733;
    }
  }
}
