@use '../../../mixin' as mx;
@use '../../../setting' as *;

.config_header_items {
  font-size: $font_size_tiny;
  width: 8.75rem;
  background-color: #fff;
  border: 1px solid #707070;
  position: absolute;
  top: 100%;
  right: 1rem;
  transform: translateY(-25%);
  z-index: 1;
  padding: 0.7em 0;

  &.fade_in {
    animation-name: fade_in;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  .inner {
    @include mx.flex_align_center();
    //background-color: red;
    width: 8rem;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;

    .divider {
      width: 100%;
      height: 1px;
      background-color: #707070;
      margin: 5px 0;
    }

    .user_name {
      .button {
        padding: 4.5px 4px;
        cursor: default;

        &:hover {
          background-color: transparent;
        }

        .button__label {
          font-size: 12px;
          padding-left: 1px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 6rem;
        }

        .button__icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .button {
      font-size: 1em;
      color: #000;
      font-weight: normal;
      padding: 4.5px 6px;

      .button__label {
        font-size: 0.625rem;
        padding-left: 1px;
      }

      .button__icon {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: $hover_primary_color;

        .button__hover_cover {
          opacity: 0;
        }
      }
    }
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.system_config_header_items {
  height: 1.5rem !important;
}
