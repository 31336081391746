@use '../../../mixin' as mx;
@use '../../../setting' as st;

.edit_form_designation {

  .dialog_contents__body {
    @include mx.flex_direction_column();
    gap: 12px;
  }

  &__item {
    &__label {
      padding: 0.5em 0;
    }

    &:first-of-type {
      .input {
        width: 100%;
        max-width: 320px;
      }
    }

    &:last-of-type {
      .edit_form_designation__item__label {
        @include mx.flex_box;
        @include mx.flex_container_wrap;
        @include mx.flex_align_center;
        span {
          font-size: st.$font_size_tiny;
        }
      }

      .edit_form_designation__item__form {
        @include mx.flex_box();
        @include mx.flex_align_center;
        gap: 8px;

        .button {
          width: 80px;
        }
      }
    }
  }
}