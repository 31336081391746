@use '../../../../setting' as *;
@use '../../../../mixin' as mx;

$border_color: #707070;

.retouch_use_image {
  @include mx.flex_box();
  height: 100%;
  gap: 5px;
  overflow-y: hidden;

  &__inner {
    @include mx.flex_direction_column();
    min-height: 372px;
    flex: 1;
    padding: 0.5em 1em;
    border: 1px solid $border_color;
    overflow: hidden;
    gap: 0.5em;

    &__header {
      // height: 3em;
      @include mx.flex_box;

      &__left {
        @include mx.flex_direction_column();
        flex: 1;
        gap: 0.5em 0;
        > div {
          flex: 1;
          @include mx.flex_box;
          @include mx.flex_align_center;

          &.check_contents {
            @include mx.flex_box;
            @include mx.flex_container_wrap;
            gap: 0.5em 1em;
            .checkbox {
              white-space: nowrap;
            }
          }
        }
      }

      &__right {
        .bordered_left_label_item {
          min-width: 11em;
          white-space: nowrap;

          > span {
            background-color: transparent;
            border-right: 1px solid #707070;
          }
        }
      }
    }

    &__body {
      @include mx.flex_box();
      @include mx.flex_container_wrap();
      padding: 0 8px;
      gap: 1.25em 1em;
      // flex: 1;
      overflow-y: auto;

      /* 一覧 */
      &.list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));

        .retouch_list_item {
          width: 100%;
        }
      }

      /* ６コマ表示 */
      &.six {
        $blank_height: 3.5em;
        gap: $blank_height 1.8%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
        flex: 1;
        padding-top: $blank_height !important;

        .retouch_list_item {
          width: 100%;
          height: 100%;

          &__header {
            gap: 6px;
          }

          &::before {
            all: initial;
          }

          &__body {
            height: 100%;
            &__wrap {
              position: static;
            }
            &__inner {
              position: static;
            }
            &::before {
              all: initial;
            }
          }

          &__thumb {
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
        }
      }

      /* １コマ表示 */
      &.one {
        border-top: 1px solid $border_color;
        padding: 1rem 10px 0.5em;
        height: 100%;
        overflow: hidden;

        .one_conts {
          width: 100%;
          height: 100%;
          position: relative;

          .zoom_menu {
            @include mx.flex_box();
            position: absolute;
            gap: 3px;
            top: -4px;
            right: 0;
          }

          .next_btn,
          .pre_btn {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            z-index: 1;

            &:before {
              font-size: 10px;
            }
          }

          .next_btn {
            right: 0;
          }

          &__body {
            @include mx.flex_box();
            @include mx.flex_space_between();
            height: 100%;
            gap: 1em;
            overflow: hidden;

            &__left,
            &__right {
              min-width: 6em;
              // min-width: 115px;
            }

            &__left {
              z-index: 1;
              position: relative;
              mix-blend-mode: difference;
              height: max-content;
              // transform: translateX(3.725em);
              .checks {
                @include mx.flex_direction_column();
                gap: 9px;
                position: absolute;
                top: 0;
                width: max-content;
              }
            }

            &__center {
              flex: 1;
              @include mx.flex_direction_column;
              gap: 1em;
              overflow: hidden;

              .one_conts__thumb {
                flex: 1;
                overflow: hidden;
                // overflow: auto;
                position: relative;
                .base_image {
                  position: absolute;
                  right: 0;
                  left: 0;
                  margin: auto;
                  overflow: auto;

                  &.scale_option {
                    top: 0;
                    bottom: 0;
                  }
                }

                &__name {
                  text-align: center;
                  @include mx.flex_all_center;
                  gap: 0.5em 1em;

                  > span {
                    line-height: 1.5;
                    &:first-of-type {
                      @include mx.ellipsis;
                    }
                    &:last-of-type {
                      white-space: nowrap;
                    }
                  }
                }

                //  .one_conts__thumb
              }
            }

            //  .one_conts__body
          }

          // .one_conts
        }
      }
      // .retouch_use_image__inner__body
    }

    /* 1コマ表示時用画像一覧コンテナ */
    &.one_list {
      flex: none;
      width: 18rem !important;
      min-width: 18rem !important;
      padding: 0.5em 0.5em 5px;
      overflow: hidden;

      &.closed {
        height: auto;
      }

      .row_list__header {
        @include mx.flex_box();
        @include mx.flex_align_center();
        @include mx.flex_space_between();
        font-size: $font_size_small;
        height: 2em;
        background-color: #4d4d4d;
        padding: 0 8px;

        .rect_icon {
          display: none;
          // background-color: #949494;
          // width: 18px;
          // height: 18px;
        }
      }

      .row_list__body {
        flex: 1;
        @include mx.flex_direction_column;
        @include mx.flex_align_start;
        gap: 10px;
        overflow-x: auto;

        .retouch_list_item {
          min-width: 18.5em;
          width: 18.5em;
          &__header {
            // font-size: calc(1em - 2px);
          }

          &__name {
            font-size: $font_size_tiny;
          }
        }
      }
    }

    .checkbox {
      label {
        &:before {
          background: transparent;
        }

        &:after {
          color: #fff;
        }
      }
    }

    .base_image {
      img {
        -webkit-user-drag: none;
        -moz-user-select: none;
      }
    }

    // .retouch_use_image__inner
  }

  &__none {
    height: 100%;
    width: 100%;
    @include mx.flex_all_center;
    font-size: 1.5rem;
  }
  // .retouch_use_image
}

.retouch_list_item {
  width: 18.95em;
  position: relative;
  &__body {
    cursor: pointer;
  }
  &.selected {
    .retouch_list_item__body__inner {
      &::before {
        $triangle_size: 1em;
        color: $selected_color;
        display: block;
        content: '';
        border-top: $triangle_size solid currentColor;
        border-right: $triangle_size solid currentColor;
        border-bottom: $triangle_size solid transparent;
        border-left: $triangle_size solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &:before {
    display: block;
    content: '';
    padding-top: 124.6%;
  }
  &.one_list_item {
    &::before {
      padding-top: 108.5%;
    }
  }

  &__body {
    width: 100%;
    border: 1px solid $border_color;
    position: relative;

    &:before {
      display: block;
      content: '';
      padding-top: 108.3%;
    }

    &__wrap {
      @include mx.flex_box();
      @include mx.flex_align_end();
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__inner {
      @include mx.flex_direction_column();
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 1em 1.25em;
      gap: 1em;
    }
  }

  &__selected_tag {
    $tag_height: 3em;
    font-size: mx.$font_size_tiny;
    position: absolute;
    top: calc(-#{$tag_height});
    left: 6px;
    width: calc(6em + 2px);
    height: $tag_height;
    line-height: $tag_height;
    text-align: center;
    background-color: #000;
    border: 1px solid $border_color;
    border-bottom: none;
    color: #fff;

    > span {
      height: 1em;
      line-height: 1em;
    }
  }

  &__header {
    height: 0;
    position: relative;

    .checks {
      font-size: calc(1em - 2px);
      height: max-content;
      background-color: rgba($color: #fff, $alpha: 0.8);
      @include mx.flex_direction_column();
      padding: 0.5em;
      gap: 4px;
      position: absolute;
      top: 0;
      left: 0;
      .checkbox {
        &__label {
          // mix-blend-mode: difference;
          color: #000;
          white-space: nowrap;
        }
      }
    }
    .goods_name {
      text-align: center;
    }
  }

  &__thumb {
    flex: 1;
    @include mx.flex_all_center;
    overflow: hidden;
    cursor: pointer;
  }

  &__name {
    line-height: 1.5;
    text-align: center;
    word-break: break-all;
    // @include mx.ellipsis_nth_clamp(2);

    > div {
      @include mx.ellipsis;
    }

    // .retouch_list_item__name
  }
}
