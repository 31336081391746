@use '../../../../mixin' as *;

.layout_menu {
  > div {
    @include flex_box();
    padding: 2px 8px;
    gap: 5px;

    &.main_menu {
      flex: 1;
      @include flex_container_wrap;
      .button {
        padding: 0;
        width: 7em;
      }
    }

    &:nth-of-type(2) {
      @include flex_box;
      @include flex_container_wrap;
      .guarantee_area_switch,
      .layout_switch {
        @include flex_box();
        @include flex_align_center();
        // padding: 2px 8px;
        gap: 5px;
        white-space: nowrap;
        padding: 0.3em;
        background-color: #2e2e2e;
        border-radius: 4px;

        > div {
          @include flex_box();
          @include flex_align_center();
          gap: 3px;
        }
      }
    }

    &:last-of-type {
      border-left: 2px solid #686868;
      @include flex_container_wrap;
      @include flex_content_center;
      .button {
        width: 9.5em;
      }
    }

    .button {
      font-weight: normal;

      &.btn_back {
        width: 7.6em;
      }
    }
  }

  .main_menu_form {
    position: absolute;
    left: 8px;
    min-width: 74.4em;
    width: 74.4em;
    height: auto;
    padding: 5px;
    background: $form_border_color;

    &__dimmer {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: #00000080;
      z-index: 10;
    }

    &__inner {
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 5px;

      .left_label_form {
        $form_height: 2.4em;
        height: $form_height;
        gap: 3px;

        span {
          line-height: $form_height;
          // margin-right: 3px;
          background-color: #4b73e733;
          color: $text_color;
        }

        .input,
        select {
          width: 100%;
        }

        .input,
        .select,
        .textarea {
          font-size: $font_size_tiny;
          border-color: $form_border_color;
        }
        .input {
          .fa-calendar {
            input[type='date'] {
              right: 0;
              width: 4em;
            }
          }
        }
        .textarea {
          padding: 0.5em;
          font-weight: normal;
        }
      }
    }

    /* 個別 */
    .item_album_location {
      width: 17.6em;
      height: 100%;
    }

    /* 日付 */
    .item_album_date {
      min-width: 213px;
      .input {
        min-width: initial !important;
        max-width: 231px;
      }
    }

    /* 日付形式 */
    .item_album_date_format {
      flex: 1;

      span {
        min-width: 5.5em;
      }

      > div {
        width: 100%;
        height: 100%;

        .select {
          width: 50%;
        }
      }
    }

    /* ページ */
    .item_album_page {
      width: 10.8em;

      .input {
        text-align: center;
      }
    }

    /* 記載箇所 */
    .item_album_position {
      flex: 1;

      span {
        min-width: 55px;
      }

      .input {
        @include flex_box();
        @include flex_align_center();
        gap: 0.5em;

        .checkbox {
          height: 100%;
          @include flex_box;
          @include flex_align_center;

          &__form {
            font-size: 1.5em;
            border-color: #d4d4d5;
            &.checked {
              border-color: #d4d4d5;
            }
          }

          label {
            padding-left: 16px;

            &::before {
              width: 16px;
              height: 16px;
              border-color: $form_border_color;
            }

            &::after {
              width: 6px;
              height: 9px;
              border-width: 2px;
              top: 2px;
              left: 5px;
            }
          }

          span {
            @include flex_content_start();
            background-color: transparent;
            min-width: auto;
            width: auto;
            padding: 0 3px;
            margin-right: 0;
            line-height: 1;
          }
        }
      }
    }

    // .main_menu_form
  }
}
