@use '../../../setting' as st;
@use '../../../mixin' as mx;

// 仕様なしにつき暫定適用
.tooltip {
  // font-size: 100%;
  font-size: 0.75rem;
  font-weight: normal;
  font-style: normal;
  position: absolute;
  // min-width: 4em;
  max-width: 30em;
  padding: 0.5em 0.75em;
  background-color: #fff;
  color: #383838;
  border-radius: 2px;
  line-height: 1.5;
  z-index: 1;
  text-align: left;
  @include mx.box_shadow($x: 0, $y: 0, $blur: 3px, $color: #383838a0);
  opacity: 0;
  animation: 0.2s linear 0.2s fade_in forwards;
  white-space: nowrap;

  &.error {
    color: #fff;
    border-radius: 0.25em;
    background-color: st.$danger_color;
    letter-spacing: 1px;
    font-weight: bold;
    // box-shadow: none;
    border: none;
  }

  &.open_top {
    bottom: 100%;
    transform: translateY(-0.25em);
  }

  &.open_bottom {
    top: 100%;
    transform: translateY(0.25em);
  }

  &.direction_right {
    left: 0;
  }

  &.direction_left {
    right: 0;
  }

  &__message_row {
    word-break: break-all;
  }

  .triangle_icon {
    position: absolute;
    color: st.$danger_color;
    margin-top: 0; // login.scssスタイル打ち消し

    &.top,
    &.bottom {
      left: 2em;
    }
    &.top {
      bottom: 100%;
      transform: translateY(31%);
    }
    &.bottom {
      top: 100%;
      transform: translateY(-31%);
    }
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
