@use '../../../mixin' as mx;
@use '../../../setting' as st;

.delivery_address_info_input {
  &__inner {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__body {
    flex: 1;
    overflow-y: auto;
    @include mx.flex_direction_column();
    gap: 1em 0;
  }

  .dialog_contents__body {
    width: 544px;
    height: 100%;
    @include mx.flex_direction_column();
    gap: 1em 0;
    padding-bottom: 2px;

    .bottom_border_header {
      @include mx.flex_box();
      @include mx.flex_space_between();

      > div {
        + div {
          font-weight: normal;
          color: st.$danger_color;

          &:before {
            content: '\203B';
            font-weight: 100;
          }
        }
      }
    }

    .top_label_ui {
      &__label {
        font-size: 14px;
        height: auto;
        line-height: 1.5em;
      }

      .input,
      .select select {
        font-size: 100%;
      }

      > .input,
      :not(.postal_form) .input {
        width: 22.85em;
      }

      .select {
        color: #707070;
        height: 32px;

        select {
          // line-height: 3.2;
          line-height: 32px;
        }
      }

      /* 個別 */
      &.form_prefecture,
      &.form_tel,
      &.form_delivery_time,
      &.form_sales_store {
        .input,
        .select {
          width: 11.1em !important;
        }
      }
    }
  }
}
