
.info {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label {
    display: block;
    margin-bottom: 0.2rem;
  }

}

.download_expired {
  margin: 0.5rem 0;

  &__label {
    display: block;
    margin-bottom: 0.2rem;
  }

  &__input {
    display: flex;
    align-items: center;

    .input {
      width: 10em;
      margin-right: 0.2rem;

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}

.shop_detail__footer {
  width: 100%;
  align-items: center;
  justify-content: center;
  .button {
    margin: 0 1rem;
  }
}
