@use '../../../../src/_setting' as st;
@use '../../../../src/mixin' as mx;

.urgent_notice {
  $close_icon_width: 2em;
  $content_space: 0.5em;
  $padding_size: 0.5em;

  font-size: 100%;
  width: 100%;
  height: 0;
  background-color: st.$danger_color;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.44px;
  // padding-left: calc(#{$close_icon_width} + #{$content_space} + #{$padding_size});
  padding: 0 $padding_size;
  // padding-right: $padding_size;
  @include mx.flex_box;
  gap: $content_space;
  opacity: 0;

  // &.open,
  &.close {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
  &.open {
    // animation-name: open_anime;
    padding-top: $padding_size;
    padding-bottom: $padding_size;
    height: auto;
    opacity: 1;
  }
  &.close {
    animation-name: close_anime;
  }

  &__inner {
    flex: 1;
    @include mx.flex_box;
    @include mx.flex_content_center;
  }

  &__title {
    // white-space: nowrap;
  }
  &__close {
    // min-width: $close_icon_width;
    // width: $close_icon_width;
    // position: relative;
    // background: none;
    // border: none;
    // color: inherit;
    // cursor: pointer;

    // &::before {
    //   font-weight: bold;
    //   font-size: 1.5em;
    //   width: 1em;
    //   display: block;
    //   content: '\30FC';
    //   pointer-events: none;
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   margin: 0 auto;
    //   top: 0;
    // }
  }

  // @keyframes open_anime {
  //   from {
  //     padding-top: 0;
  //     padding-bottom: 0;
  //   }
  //   to {
  //     padding-top: $padding_size;
  //     padding-bottom: $padding_size;
  //     height: auto;
  //   }
  // }

  @keyframes close_anime {
    from {
      padding-top: $padding_size;
      padding-bottom: $padding_size;
      height: auto;
      opacity: 1;
    }
    to {
      padding-top: 0;
      padding-bottom: 0;
      height: 0;
      opacity: 0;
    }
  }
}
