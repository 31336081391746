.layout_header {

  &__right {
    padding: 0 0.5em;

    .btn_template_change_info {
      font-weight: normal;
    }
  }

  &__menu {
    white-space: nowrap;
    width: 100px;
  }

  &__space {
    width: 100px;
  }
}
