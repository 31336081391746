@use '../../../setting' as st;
@use '../../../mixin' as mx;

.upload_manager {
  font-size: 1rem;
  gap: 2.5rem;

  &__body {
    flex: 1;
    width: 92.2vw;
    margin: auto;
    @include mx.flex_direction_column;
    overflow: hidden;

    // .upload_manager__body
  }

  &__ttl {
    font-size: 1.25em;
    font-weight: bold;
    color: #fff;
    line-height: 2;
    padding: 0 1em;
    background-color: st.$danger_color;

    // .upload_manager__ttl
  }

  &__contents {
    padding: 3em 1.5em;
    flex: 1;
    @include mx.flex_direction_column;
    gap: 0.75em;
    overflow: hidden;

    // .upload_manager__contents
  }

  &__table {
    flex: 1;
    overflow: hidden;

    .table {
      // head
      &__head_0 {
        min-width: 6em;
      }
      &__head_1 {
        min-width: 11.2em;
      }
      &__head_2 {
        // お客様名が３０字以上入力不可＆左右のバッファ込みの幅
        min-width: 32em;
      }
      &__head_3 {
        min-width: 12em;
      }
      &__head_4 {
        min-width: 21em;
      }
      // data
      &__data_1,
      &__data_3,
      &__data_4 {
        text-align: center;
      }

      &__data_4 {
        .error {
          color: st.$danger_color;
        }
      }
    }

    &__menu {
      /* 子要素ではないがテーブルに帰属する要素のためこの位置 */
      @include mx.flex_box;
      @include mx.flex_space_between;

      &__batch {
        @include mx.flex_box;
        gap: 0.5em;
        padding: 0 0.5em;

        .button {
          width: 8.545em;
        }
        // .upload_manager__table__menu__batch
      }

      &__del {
        width: 6.333em;
      }

      .button {
        height: 3em;
      }
      // .upload_manager__table__menu
    }
    // .upload_manager__table
  }

  &__actions {
    padding-top: 0.75em;
    padding-bottom: 1em;
    @include mx.flex_box;
    @include mx.flex_content_end;
    gap: 0.5em;

    .button {
      height: 3em;
      width: 9em;
    }
    // .upload_manager__actions
  }
}
