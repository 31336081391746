@import "/src/mixin";

.retouch_header {

  &__menu {
    .menu_file {
      width: 15em;
    }
  }
}
