@use '../../../setting' as *;

.change_favorite_brand_name {
  padding: 0 2em;

  .top_label_ui {
    width: 90%;
    margin: 1em auto 0;
    .input {
      width: 100%;
    }

    &__caution {
      margin-top: 0.5em;
      color: $danger_color;
    }
  }
}
