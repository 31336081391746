@use '../../../mixin' as mx;

.awaiting_ellipsis {
  $duration: 1s;
  @include mx.flex_box;
  @include mx.flex_align_center;
  > div {
    display: inline-block;
    border-radius: 50%;
  }
  &_dot_0,
  &_dot_1,
  &_dot_2 {
    -webkit-animation: dot_scale $duration infinite, cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: dot_scale $duration infinite, cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  &_dot_0 {
    animation-delay: -0.24s;
  }
  &_dot_1 {
    animation-delay: -0.12s;
  }
  &_dot_2 {
    animation-delay: -0s;
  }

  @keyframes dot_scale {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.3);
    }
  }
}
