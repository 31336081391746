@use '../../../mixin' as *;

.angle_icon {
  @include flex_box();
  @include flex_content_center();
  @include flex_align_center();
  height: 1em;
  width: 1em;
  position: relative;
  font-size: 1em;

  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    border-left: solid 2px currentColor;
    border-top: solid 2px currentColor;
  }

  &.left {
    &::before {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  &.right {
    &::before {
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
  &.top {
    &::before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &.bottom {
    &::before {
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
    }
  }
}
