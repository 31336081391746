@use '../../../mixin' as mx;

.color_icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-color: transparent;

  &.circle {
    border-radius: 50%;
  }
}
