@import "/src/setting";
@import "/src/mixin";

.pagination {
  @include flex_box();

  li {
    cursor: pointer;
    user-select: none;
    text-align: center;
    font-size: 14px;
    line-height: 1.3;
  }

  &__item {
    display: inline-block;
    width: 1.5em;

    &:not(.first_item) {
      border-left: 1px solid $border_default_color;
    }

    &.active {
      color: red;
    }
  }
}
