@use '../../../mixin' as *;

$border: #AAAAAA;

.dialog__contents {
  &.c_folder_select_dialog {
    overflow-x: auto;

    .search_cnt {
      margin-bottom: 0.25rem;
      display: flex;
      gap: 0.5rem;
      justify-content: end;
      align-items: center;

      >button {
        height: 30px;
      }
    }
  }
}

.file_select {
  width: 700px;
  height: 400px;
  display: flex;
  border: $border solid 1px;
  border-radius: 4px;
  padding: 4px 0;

  &__list_area {
    width: 180px;
    padding: 10px 5px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #EEE;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888888;
      border-radius: 8px;
      box-shadow: none;
    }


    &__order_name {
      font-size: 0.9em;
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 25px;
      padding-left: 5px;
      border-radius: 4px;
    }

    &__folder_name {
      font-size: 0.9em;
      display: flex;
      align-items: center;
      line-height: 25px;
      cursor: pointer;
      border-radius: 4px;
    }

    img {
      margin-right: 2px;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__folder_wrap {
    position: relative;

    &__cnt {
      display: flex;
      gap: 0.25rem;
      align-items: center;
    }

    .checkbox_wrap {
      min-width: 14px;
    }
  }

  &__thumb_area {
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      padding: 5px;
    }
  }

  &__test {
    width: 340px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #EEE;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888888;
      border-radius: 8px;
      box-shadow: none;
    }

    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4px;
      margin: 4px;
      width: 157px;
      border-radius: 4px;
      box-sizing: border-box;

      &__wrap {
        position: relative;
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
      }

      &.clickable {
        cursor: pointer;
      }

      &.prev-select {
        border: 1px solid rgba(213, 229, 243, 0.6);
      }

      label {
        font-size: 0.8em;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }
    }
  }
}


.active {
  background-color: #d5e5f3;
}

.file_select_footer {
  justify-content: center;

  .button {
    margin: 0 10px;
  }
}