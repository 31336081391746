@use '/src/components/pages/layout_retouch_common';
@use '/src/setting' as setting;
@use "./common";
@use "./form";

body {
  height: 100vh;
  // min-height: 1050px;
  // min-width: 1366px;
  font-size: 16px;
  font-family: "Noto Sans JP", "Helvetica", "Arial", "Roboto", sans-serif;
}

#root {
  height: 100%;
  background-color: setting.$base_bg_color;
}
