@use '../../../../mixin' as mx;
@use '../../../../setting' as *;

.layout_search_template {
  &__header {
    position: relative;

    .open_btn {
      font-size: 10px;
      width: 1em;
      height: 1em;
      position: absolute;
      top: 0;
      right: 1em;
      height: 100%;
      background: none;
      @include mx.flex_all_center;
      cursor: pointer;

      &::before,
      &::after {
        height: 0.1em;
      }

      &:before {
        // background: none;
      }
    }
  }

  // layout_search_template__header

  &__body {
    @include mx.flex_box;
    @include mx.flex_container_wrap;
    gap: 4px;
    padding: 6px 6px 0;

    > div {
      font-size: $font_size_tiny;
      @include mx.flex_box;
      @include mx.flex_align_center;

      &:first-of-type,
      &:nth-of-type(6),
      &:last-of-type {
        width: 100%;
      }

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: calc(50% - 2px);
      }

      &:last-of-type {
        @include mx.flex_space_between;

        .button {
          width: 5em;
          font-weight: normal;
          padding: 0;

          &:first-of-type {
            margin-left: auto;
          }

          + .button {
            margin-left: 4px;
          }
        }
      }
    }

    // layout_search_template__body
  }

  .left_label_form {
    .input {
      color: $layout_page_font_color;
    }
  }

  // layout_search_template
}
