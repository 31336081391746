@use '../../../../mixin' as mx;

.surrogate_order_mode_config {
  .radio_buttons_box {
    gap: 0 1.2em;
  }
}

.shop_management_email {
  @include mx.flex_box();

  &__button {
    @include mx.flex_all_center();
    padding-left: 0.5rem;
  }
}

.shop_management_password {
  padding-bottom: 2rem;
}