@import '../../../mixin';

.warning_icon {
  width: 5em;
  height: 5em;
  &__dimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(11, 12, 14, 0.6);
    @include flex_all_center;
  }
  img {
    opacity: 1;
  }
}
