@use '../../../mixin' as mx;
@use '../../../setting' as *;

.type_select_button {
  @include mx.flex_all_center;
  font-size: $font_size_small;
  padding: 1px 1em;
  height: 2em;
  cursor: pointer;
  white-space: nowrap;

  &.current {
    background-color: $primary_color;
    color: #fff;
    font-weight: bold;
    border-radius: 1em;
  }
}

.order_table {
  @include mx.flex_direction_column;
  flex: 1;
  margin-top: 1rem;
  overflow: hidden;

  &__type_select {
    overflow-x: auto;
    padding-bottom: 2px;
    @include mx.scrollbar_no_track;
    > div {
      @include mx.flex_box;
      @include mx.flex_align_center;
      // min-width: 855px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      .button {
        background: none;
        color: $primary_color;
        height: 24px;
        font-weight: normal;
      }
    }
  } // order_table__type_select

  &__menu_btn {
    @include mx.flex_box;
    @include mx.flex_align_center;
    margin-top: 2rem;
    gap: 1rem;

    .button {
      padding: 1px 1rem;

      &:last-of-type {
        margin-left: auto;
        .button__icon {
          height: 1.3em;
        }
      }
    }

    .proxy_mode_form {
      font-size: 1rem;
      font-weight: bold;
      @include mx.flex_box;
      gap: 0 0.5em;
      &__label {
        > div {
          &:last-of-type {
            font-size: 0.75em;
          }
        }
      }
      .input {
        width: 10em;
        height: 2.2rem;
      }
    }
  } // order_table__menu_btn

  &__table {
    flex: 1;
    margin-top: 0.5rem;
    overflow: hidden;

    .table {
      padding-right: 1px; // スクロールバーとの間隔調整用
      &__head_0 {
        width: 3rem;
        min-width: 3rem;
      }
      &__head_1,
      &__head_3 {
        width: 8rem;
        min-width: 8rem;
      }
      &__head_4,
      &__head_5 {
        width: 10rem;
        min-width: 10rem;
      }
      &__head_6 {
        width: 5rem;
        min-width: 5rem;
      }
      &__head_7 {
        width: 5rem;
        min-width: 5rem;
      }

      &__data_0 {
        .checkbox {
          @include mx.flex_box;
          @include mx.flex_content_center;
        }
      }
      &__data_1,
      &__data_3,
      &__data_4,
      &__data_5,
      &__data_6 {
        text-align: center;
        img {
          display: inline-block;
          width: 1.5em;
          height: 1.5em;
          &.locked {
            color: $primary_color;
          }
        }
      }
      &__data_1 {
        .order_status__inner {
          width: 8em;
        }
      }
      &__data_6 {
        max-width: 8.25rem;
        > div {
          @include mx.flex_box;
          @include mx.flex_align_center;
          gap: 0 0.25em;
          > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      &__data_7 {
        .button {
          margin: auto;
        }
      }
    }
  } // order_table__table
}
