@use '../../../../mixin' as mx;
@use '../../../../setting' as *;

.layout_edit_template {
  height: 100%;
  position: relative;

  .tool_box {
    font-size: 10px;
    position: fixed;
    @include mx.flex_box;
    gap: 0.25em;

    &__group {
      position: relative;
      padding: 0.5em;
      background-color: #383838;
      border-radius: calc(1em / 6);
      border-top-left-radius: 0;

      &__label {
        position: absolute;
        bottom: calc(100% - 0.25em);
        left: 0;
        padding: 0.25em 0.5em 0;
        background-color: #383838;
        border-top-left-radius: calc(1em / 6);
        border-top-right-radius: calc(1em / 6);
      }

      &__menu {
        width: 4em;
        height: 4em;
        background-color: #606060;
        border-radius: calc(1em / 6);
        cursor: pointer;
        @include mx.flex_all_center;

        &__wrap {
          @include mx.flex_box;
          gap: 0.5em;
        }

        img {
          height: 60%;
          // height: 1.5em;
        }
      }
    }
  }

  .image_frame {
    $ruler_width: 20px;
    padding-top: ($ruler_width);
    padding-left: $ruler_width;
    height: 100%;
    width: 100%;

    &.close {
      padding-top: 0;
      padding-left: 0;
      .ruler_x,
      .ruler_y {
        display: none;
      }
    }

    &__inner {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: visible !important;
      .ruler_x {
        transform: translateY(-100%);
      }
      .ruler_y {
        transform: translateX(-100%);
      }
    }

    &__ruler_origin {
      width: $ruler_width;
      height: $ruler_width;
      position: absolute;
      top: -$ruler_width;
      left: -$ruler_width;
      background-color: #505050;
      z-index: 1;
      overflow: hidden;

      &::before,
      &::after {
        display: block;
        content: '';
        background-color: #ffffff10;
        position: absolute;
      }
      &::before {
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 6px;
      }
      &::after {
        width: 1px;
        height: 100%;
        top: 0;
        right: 6px;
      }
    }
  }
}

@mixin animation($animationName, $animationDuration: 0.6s, $iteration: 1) {
  -webkit-animation-name: $animationName;
  animation-name: $animationName;
  -webkit-animation-duration: $animationDuration;
  animation-duration: $animationDuration;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-iteration-count: $iteration;
}

.error_toast {
  $error_color: #d70b24;
  @include mx.flex_box();
  @include mx.flex_align_center();
  max-width: 30em;
  padding: 0.75em;
  gap: 0 0.75em;
  background-color: $error_color;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: default;
  position: absolute;
  z-index: 10000000;
  left: 0;
  bottom: 0;
  overflow: hidden;

  &__icon {
    min-width: 1.5em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    @include mx.flex_all_center();
    background-color: #fff;
    color: $error_color;
    i {
      transform: translateX(12.5%);
    }
  }

  &__txt {
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
    font-weight: bold;
    flex: 1;
    overflow-wrap: break-word;
    @include mx.selectDisabled();
  }

  &__close {
    color: #adadad;
  }

  &.enter {
    @include animation($animationName: enterAnimation, $animationDuration: 0.2s, $iteration: 2);
    visibility: visible;
  }
  &.leave {
    // @include animation($animationName: leaveAnimation);
    visibility: hidden;
  }
}

@keyframes enterAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes leaveAnimation {
  0% {
    right: 1em;
  }
  40% {
    transform: scale(0.8);
    opacity: 0.8;
    top: 0.5em;
    right: 3em;
  }
  100% {
    transform: scale(0);
    opacity: 0;
    right: -100%;
  }
}
