@use '../../../setting' as *;
@use '../../../mixin' as mx;

.button {
  font-size: 100%;
  font-family: inherit;
  @include mx.flex_all_center;
  padding: 1px 1em;
  border-radius: $border_default_radius;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  position: relative;
  border: none;
  white-space: nowrap;
  // overflow: hidden;

  &__label {
    // display: inline-block;
    // height: 1em;
    line-height: 1;
  }

  &__hover_cover {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
  }

  &:hover {
    .button__hover_cover {
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0.4;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  /* color */
  &.primary {
    background-color: $primary_color;
    color: #fff;
  }

  &.secondary {
    background-color: $secondary_color;
    color: #fff;
  }

  &.warning {
    background-color: $warning_color;
    color: #000;
  }

  &.danger {
    background-color: $danger_color;
    color: #fff;
  }

  &.light {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;

    &:hover {
      border-color: rgba($color: #000, $alpha: 0.6);
    }
  }

  &.dark {
    background-color: #595959;
    color: #fff;
  }

  &.blue_cyan {
    background-color: #18beff;
    color: #000;
  }

  /* size */
  &.sm {
    font-size: 12px;
    height: 2em;
    border-radius: 2px;
    padding: 1px 0.5rem;
  }

  &.md {
    font-size: 14px;
    height: 2.5em;
    border-radius: 2px;
  }

  &.lg {
    font-size: 16px;
    height: 3em;
  }

  /* アイコン付きボタン */
  &.icon_button {
    .button__icon {
      @include mx.flex_all_center();
      margin-right: 5px;
      height: 1.6em;

      img {
        height: 100%;
      }
    }

    &.sm {
      .button__icon {
        margin-right: 4px;
        height: 1.2em;
      }
    }
  }
}
