.connection_status_bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background-color: rgba($color: #000000, $alpha: 0.1);
  z-index: 200;

  &::before,
  &::after {
    height: 100%;
    display: block;
    content: '';
    background-color: currentColor;
    // mix-blend-mode: difference;
    border-radius: 0.125em;
    position: absolute;
    transform: translateX(-100%);
    animation: 3s infinite;
  }

  &::before {
    width: 0%;
    animation-name: slide_in_before;
  }
  &::after {
    width: 60%;
    animation-name: slide_in_after;
  }
}

@keyframes slide_in_before {
  0% {
    transform: translateX(-100%);
  }
  33% {
    transform: translateX(40%);
    animation-timing-function: ease-in;
  }
  67% {
    transform: translateX(140%);
    width: 80%;
  }
  100% {
    transform: translateX(140%);
    width: 80%;
  }
}

@keyframes slide_in_after {
  0% {
    transform: translateX(-100%);
    width: 70%;
    left: 0;
  }
  58% {
    transform: translateX(-100%);
    width: 70%;
    left: 0;
  }
  100% {
    transform: translateX(0);
    left: 100%;
    width: 1%;
    animation-timing-function: ease-out;
  }
}
