@charset "utf-8";

$base_color: #1451a1;
$base_bg_color: #fbfbfb;

/* color */
$error_text_color: #ff0000;
$alert_color: #ff0000;
$text_color: #4d4d4d;
$comment_color: #777777;
$bg_dark: #5f6368;

$primary_color: #4b73e7;
$secondary_color: #f5a000;
$danger_color: #d70b24;
$warning_color: #fcdb2c;
$hover_primary_color: #4b73e71a;
$selected_color: #fff9d9; //選択
$invalid_color: #cccccc; //無効、削除

/*border*/
$border_default_color: #cccccc;
$border_gray_normal: #888888;
$border_gray_light: #70707091;
$border_primary: $base_color;
$border_secondary: #0000001a;
$border_default_radius: 4px;

$footer_color: #1451a1;

/*form*/
$form_border_color: #e0e0e0;
$placeholder_color: #888888;
$form_color: #e2e2e2;
$form_text_color: #5f6368;
$form_checkbox_border_color: #707070;
$form_checkbox_background_color: #ffffff;
$form_error_border_color: #ff0000;
$form_error_bg_color: #fff6f6;
$form_error_text_color: #9f3a38;
$form_focus_color: #4b73e7;

/*radio*/
$margin_bottom_default: 15px;
$list_id_background_color: #5f6368;

$hover-color: #dfe1e5;
$font-primary: $base_color;
$font-dark: #5f6368;
$font-white: #ffffff;

$emphasis_color1: #f56922;
$emphasis_color2: #ebb521;
$emphasis_color3: #de2a7b;
$emphasis_color_blue: #0069b4;

$background_color: #f4f3f2;
$header_background_gray: #4d4d4d0d;

/*button*/
$btn_primary_background_color: $base_color;
$btn_primary_color: #ffffff;

$btn_secondary_background_color: #57b058;
$btn_secondary_color: #ffffff;

$btn_tertiary_background_color: $base_color;
$btn_tertiary_color: #ffffff;

$btn_dark_background_color: #5f6368;
$btn_dark_color: #ffffff;

/*table*/
$table_color: #d1dded;
$table_thead_background_color: #f7f7f7;
$table_thead_color: #e0e0e0;
$table_thead_line_color: #3554844d;
$table_border_color: #888888;
$table_selected_color: #e4e9fa; //選択
$table_invalid_color: $invalid_color; //無効、削除

/**staff-table*/
$staff_table_thead_background_color: #FF6699;
$staff_table_td_even_color: #FFD3DE;
$staff_table_td_odd_color: #FFEAEF;

/*$table_thead_color:#FFFFFF;*/
$table_td_even_color: #ffffff;
$table_td_odd_color: #e0e0e0;

/*scrollbar*/
$scrollbar_bg_color: #e0e0e0;
$scrollbar_color: #1451a1;

/*hr*/
$hr_color: rgba(0, 0, 0, 0.03);

$menu_background_gray: #4d4d4d0d;
$ui_header_color: #888888;

/* font-size */
$font_size_small: 14px;
$font_size_tiny: 12px;

/* レイアウト、レタッチ画面用 */
$layout_page_font_color: #f7f7f7;
$layout_item_border_color: #707070;
$layout_item_outer_pd: 4px;
$layout_label_color: #747474;
$layout_break_point: 966px;
$layout_page_category_bg_color: #383838;
$layout_active_color: #20a4f7;
$category_bg_color: #383838;

/* icon */
$icon_color_gray: #707070;

$black_page_gray: #383838;
