@use '../../../setting' as st;
@use '../../../mixin' as mx;

.switch {
  height: 2.5em;
  width: max-content;
  border: 1px solid st.$primary_color;
  border-radius: 0.25em;
  overflow: hidden;

  &__button {
    font-size: 100%;
    font-family: inherit;
    height: inherit;
    width: 7em;
    border: none;
    @include mx.appearance_none;
    background-color: #fff;
    color: st.$primary_color;
    cursor: pointer;
    word-break: break-all;

    &:not(.first_btn) {
      border-left: 1px solid st.$primary_color;
    }
    &.selected {
      background-color: st.$primary_color;
      color: #fff;
      font-weight: 600;
      pointer-events: none;
    }
  }
}
