@use '../../../mixin' as *;

.switch_icon {
  @include flex_all_center();
  min-width: 16px;
  min-height: 16px;

  /* フォルダー */
  &.folder {
    img {
      width: 1em;
      height: 1em;
    }

    &:hover {
    }
  }

  /* ツリー構造 */
  &.tree {
    font-size: 6px;

    > div {
      @include flex_all_center();
      width: 100%;
      height: 100%;
      position: relative;

      &:before {
        display: block;
        content: '';
        border-top: 1em solid currentColor;
        border-left: calc(1em / 6 * 5) solid transparent;
        border-right: calc(1em / 6 * 5) solid transparent;
        transform: rotate(-90deg);
      }

      &.open {
        &:before {
          transform: none;
        }
      }
    }
  }

  /* x軸展開・収納 */
  &.open_x {
    img {
      &.close {
        transform: rotate(180deg);
      }
    }
  }
}
