@import '../../../mixin';

.loading_circle {
  display: block;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  animation-name: loading_animation;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;

  &__dimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex_all_center;
  }
}

@keyframes loading_animation {
  to {
    transform: rotate(360deg);
  }
}
