@use 'src/mixin' as mx;
@use 'src/setting' as st;

.send_form_designation_config {
  .dialog_contents__body {
    @include mx.flex_box;
    @include mx.flex_direction_column;
  }
  &__table {
    table {
      width: 600px !important;
    }

    .table__head_0 {
      font-size: 14px;
    }

    .shipping_form_item {
      @include mx.flex_box();
      @include mx.flex_align_center();
      @include mx.flex_space_between();

      &__ttl {
        font-size: st.$font_size_small;
      }

      &__btns {
        @include mx.flex_box();
        gap: 5px;
      }
    }

    //  .send_form_designation_config__table
  }

  &__footer {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;

    &__right {
      @include mx.flex_box();
      gap: 8px;
    }
  }

  .alert_message {
    margin-bottom: 1em;
  }
}
