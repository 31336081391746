@use '../../../mixin' as mx;

.sort_boundary {
  $border_width: 2px;
  $border_length: calc(100% + 4px);
  position: relative;
  &__line {
    position: absolute;
    padding: 0;
    margin: 0;
    height: 0;
    width: 0;
    border: 0;
    border-color: currentColor;
    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
    }
  }
  &__child {
    height: 100%;
    width: 100%;
  }

  &.horizontal {
    @include mx.flex_direction_column;
    .sort_boundary__line {
      width: $border_length;
      border-top: $border_width dashed;
      &.top,
      &.bottom {
        left: -2px;
      }
      &.top {
        // top: 0;
        top: -$border_width;
      }
      &.bottom {
        // bottom: 0;
        bottom: -$border_width;
      }
      // !! こっちが擬似要素 T字を作っている
      &::before,
      &::after {
        width: 2px;
        height: 8px;
        background-color: currentColor;
        transform: translateY(calc(-50% - 1px));
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
  }
  &.vertical {
    @include mx.flex_box;
    .sort_boundary__line {
      height: $border_length;
      border-left: $border_width dashed;
      &.left,
      &.right {
        top: -2px;
      }
      &.left {
        // left: 0;
        left: -$border_width;
      }
      &.right {
        // right: 0;
        right: -$border_width;
      }

      // !! こっちが擬似要素 T字を作っている
      &::before,
      &::after {
        width: 8px;
        height: 2px;
        background-color: currentColor;
        transform: translateX(calc(-50% - 1px));
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
    }
  }
}
