@use '../../../mixin' as mx;

.image_upload_status {
  $size: 2.5em;
  width: $size;
  height: $size;
  position: absolute;
  top: 2px;
  left: 2px;
  @include mx.flex_all_center;
  overflow: hidden;
  color: #fff;

  &__uploaded,
  &__uploading {
    font-size: 1.5em;
  }
  &__uploaded {
    text-shadow: 0 0 3px #00000040;
  }

  &__uploading {
    text-shadow: 0 0 3px #00000080;
    animation: fade_up_anime 1s infinite;
  }
}

@keyframes fade_up_anime {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(-25%);
  }
}
