@use '../../../mixin' as mx;
@use '../../../setting' as st;

.retouch {

  &__body {
    flex: 1;
    overflow: hidden;
    padding: 6px;
    grid-gap: 6px;
    gap: 6px;
    color: #f7f7f7;
    &__list {
      flex: 1;
      height: 100%;
      overflow: hidden;
    }
  }

  &__footer {
    .retouch_footer {
      @include mx.flex_box();
      @include mx.flex_align_center();
      padding: 0.5em;
      gap: 1em;
      background-color: st.$black_page_gray;

      &__info {
        @include mx.flex_direction_column();
        flex: 1;
        min-width: 500px;

        > div {
          @include mx.flex_box();
          line-height: 1.5;
        }

        @include mx.mq_max();
      }

      &__size {
        @include mx.flex_box();
        @include mx.flex_align_center();
        gap: 0.5em;

        > span {
          min-width: 5em;
        }

        > .button {
          color: st.$layout_page_font_color;
          width: 2.4em;
        }
      }

      &__operation {
        @include mx.flex_box();
        gap: 0.5em;
        margin-left: auto;
        > .button {
          font-weight: normal;
          width: 12em;
        }
      }

      .button {
        height: 2.4em;
      }
    }
  }
}