@use '../../../mixin' as *;

.order_preparation {
  overflow: hidden;

  &__body {
    @include flex_box();
    flex: 1;
    overflow: hidden;
    position: relative;

    &__contents {
      @include flex_direction_column();
      flex: 1;
      padding: 0 2.5rem 1rem 0;
      // overflow-x: auto;
      overflow-x: hidden;

      &__menu {
        @include flex_box();
        flex-wrap: wrap;
        gap: 0.5rem;
      }

      &__caution {
        margin-top: 1.5rem;
        font-size: $font_size_tiny;
        color: $danger_color;
        line-height: 1.5em;
      }

      &__table {
        flex: 1;
        margin-top: 1rem;
        overflow-y: auto;

        .table {
          // @include scrollbar_radius_track;
          table {
            th,
            tr {
              font-weight: 600;
            }
          }

          &__head_0,
          &__head_3 {
            min-width: 40px;
            width: 40px;
          }
          &__head_1 {
            min-width: 100px;
            width: 100px;
          }
          &__head_2 {
            flex: 1;
            min-width: 140px;
          }
          &__head_4 {
            min-width: 48px;
            width: 48px;
          }
          &__head_5,
          &__head_6 {
            min-width: 140px;
            width: 140px;
          }
          &__head_7 {
            min-width: 72px;
            width: 72px;
          }

          &__data_0 {
            .angle_icon {
              margin: auto;
            }
          }

          &__data_1 {
            .labeled_icon_hr {
              @include flex_content_center;
              &.error {
                color: $danger_color;
              }
            }
          }
          &__data_2,
          &__data_3,
          &__data_4,
          &__data_5,
          &__data_6 {
            text-align: center;
          }
          &__data_5 {
            height: inherit;
            > div {
              height: 100%;
              > .hoverable_ele {
                height: 100%;
                @include flex_all_center;
              }
              .tooltip {
                right: auto;
                padding: 0;
                max-height: 15rem;
                overflow-y: auto;
                @include scrollbar_no_track;
                table {
                  border-radius: inherit;
                  table-layout: fixed;
                  max-width: 40em;
                  width: max-content;
                  th, td {
                    white-space: nowrap;
                  }
                  thead {
                    font-size: 0.8em;
                    height: 2.5em;
                    tr {
                      height: 100%;
                      th {
                        &:last-of-type {
                          width: 8em;
                        }
                      }
                    }
                  }
                  tbody {
                    tr {
                      background-color: #fff !important;
                      &:not(.first_row) {
                        border-top: 1px solid #e8e8e8;
                      }
                      td {
                        text-align: center;
                        padding-right: 1em;
                        padding-left: 1em;
                        overflow: visible;
                        white-space: normal;
                        &:first-of-type {
                          width: max-content;
                          max-width: 33.5em;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &__data_7 {
            > div {
              @include flex_all_center();
              gap: 0.25em;
              .checkbox {
                label {
                  &::before {
                    height: 100%;
                    width: 12px;
                    top: 0;
                    border-radius: 2px;
                    border: 1px solid #707070;
                  }

                  &::after {
                    top: 2px;
                    left: 4px;
                    width: 4px;
                    height: 7px;
                  }
                }
              }
            }
          }
        }
      }
    } // order_preparation__body__contents
    // .order_preparation__body
  }
  &__warning-icon-tooltip {
    color: $danger_color;
    font-weight: bold;
    cursor: default;
  }
}
