@use "../../../setting" as *;
@use "../../../mixin" as mx;

.checkbox {
  position: relative;
  display: inline-block;
  width: max-content;

  &__inner {
    @include mx.flex_box;
    @include mx.flex_align_center;
    // @include mx.flex_align_start;
    cursor: pointer;
    gap: 0 0.25em;
  }

  &__form {
    display: block;
    content: '';
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    border-radius: 2px;
    // margin-right: 0.25em;
    border: 1px solid;
    border-color: #707070;
    overflow: hidden;
    position: relative;

    &::before {
      $border_width: 0.165em;
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0.3em;
      height: 0.55em;
      margin: auto;
      border-right: $border_width solid currentColor;
      border-bottom: $border_width solid currentColor;
      transform: translateY(-25%) rotate(45deg);
      opacity: 0;
    }

    &.checked {
      color: $form_checkbox_border_color;
      &::before {
        opacity: 1;
      }
    }

    &.style_type_0 {
      border-color: transparent;
      background-color: #fff;
    }
    &.style_type_1 {
      $coordinate: 0%, -19%;
      &::before {
        transition: transform 0.2s, opacity 0.2s;
        transform: translate($coordinate) rotate(-90deg);
      }
      &.checked {
        background-color: $primary_color;
        border: none;
        &::before {
          width: 0.45em;
          height: 0.75em;
          transform: translate($coordinate) rotate(45deg);
          border-color: #fff;
        }
      }
    }
  }
  &__label {
    // height: 1em;
    line-height: 1em;
  }
}
