.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    display: flex;
    align-items: flex-end;

    img {
      height: 120px;
      object-fit: contain;
    }
  }
}

.animation {
  width: 100px;
  height: 40px;
  display: flex;
  position: relative;
  margin-bottom: 29px;

  .circle {
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background-color: #34344d;
    left: 15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
  }

  @keyframes circle {
    0% {
      top: 40px;
      height: 5px;
      border-radius: 50px 50px 25px 25px;
      transform: scaleX(1.7);
    }
    40% {
      height: 15px;
      border-radius: 50%;
      transform: scaleX(1);
    }
    100% {
      top: 0%;
    }
  }

  .circle:nth-child(2) {
    left: 42%;
    animation-delay: .2s;
  }

  .circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .4s;
  }

  .shadow {
    width: 15px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 40px;
    transform-origin: 50%;
    z-index: 1;
    left: 15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
  }

  @keyframes shadow {
    0% {
      transform: scaleX(1.5);
    }
    10% {
      transform: scaleX(1.3);
      opacity: .95;
    }
    20% {
      transform: scaleX(1.2);
      opacity: .9;
    }
    30% {
      transform: scaleX(1);
      opacity: .8;
    }
    40% {
      transform: scaleX(.8);
      opacity: .7;
    }
    50% {
      transform: scaleX(.75);
      opacity: .6;
    }
    60% {
      transform: scaleX(.7);
      opacity: .5;
    }
    70% {
      transform: scaleX(.6);
      opacity: .5;
    }
    80% {

      transform: scaleX(.5);
      opacity: .5;
    }
    90% {
      transform: scaleX(.45);
      opacity: .4;
    }
    100% {
      transform: scaleX(.4);
      opacity: .4;
    }
  }

  .shadow:nth-child(4) {
    left: 42%;
    animation-delay: .2s
  }

  .shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
  }
}
