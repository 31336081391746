@import '../../../mixin';
@import '../../../setting';

.news_list {
  height: 100%;
  @include flex_direction_column;

    &__return {
      @include flex_box();
      margin-bottom: 2rem;
      font-size: $font_size_tiny;

      img {
        margin-right: 0.5em;
      }
    }

  &__btn {
    display: flex;
    cursor: pointer;
  }

  &__header {
    @include flex_box();
    @include flex_align_center();
    @include flex_space_between();
    background-color: $ui_header_color;
    color: #fff;
    user-select: none;
    height: 2rem;
    padding: 0 1rem;
    font-weight: bold;
  } // news_list__header

  &__body {
    min-height: 7.5em;
    flex: 1;
    padding: 20px;
    overflow: auto;
  } // news_list__body

  &__line {
    font-size: 14px;
    min-height: 1.5em;
    line-height: 1.5;
  } // news_list__item
}
