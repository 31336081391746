@use '../../../mixin' as mx;
@use '../../../setting' as *;

.color_picker {
  position: relative;
  $border_radius: 4px;
  &__body {
    @include mx.flex_direction_column;
    @include mx.box_shadow($y: 0, $blur: 6px);
    padding: 1em;
    border: 1px solid #f2f2f2;
    width: 41rem;
    height: 28rem;
    background-color: #fff;
    z-index: 1;
    border-radius: $border_radius;
    gap: 0.5em 0;
    position: absolute;
    top: calc(100% + 0.5em);
  }

  &__code {
    @include mx.flex_box;
    @include mx.box_shadow;
    border-radius: $border_radius;
    width: 14em;
    height: 2.5em;

    > div {
      width: 2.5em;
      min-width: 2.5em;
      height: 100%;
      min-height: 100%;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      border-right: 1px solid #00000010;
    }
    > input {
      flex: 1;
      border: none;
      padding: 0 0.5em;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }

  &__chips {
    flex: 1;
    @include mx.flex_box;
    @include mx.flex_container_wrap;
    gap: 0.5em;
    overflow-y: auto;
    padding: 0 0.5em 0.5em 0;
    // .color_picker__chips
  }

  &__chip {
    @include mx.box_shadow;
    width: calc(100% / 20 - 0.475em);
    border-radius: $border_radius;
    cursor: pointer;

    &::before {
      display: block;
      content: '';
      padding-top: 100%;
    }

    &:hover {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 1.2em;
        height: 0.75em;
        border-left: 0.35em solid #fff;
        border-bottom: 0.35em solid #fff;
        transform: translateY(-25%) rotate(-45deg);
        mix-blend-mode: difference;
      }
    }
  }

  &__down {
    $size: 40%;
    position: absolute !important;
    bottom: 0;
    right: 0;
    width: $size !important;
    height: $size !important;
    background-color: #fff;
    border-top-left-radius: 1px;
    border-bottom-right-radius: inherit;
    cursor: pointer;
    font-size: 5px !important;
    // .color_picker__down
  }
}
