.cloud-folder-search-order {

  &__date {
    &__type-select {
      display: flex;
      gap: 2rem;
    }

    &__input {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .input {
        width: 10rem;
      }
    }
  }

  &__info {
    margin-top: 1rem;
    width: 100%;

    &__label {
      margin-bottom: 0.5rem;
    }

    &__input {
      width: 100%;

      .input {
        width: 100%;
      }
    }
  }
}
