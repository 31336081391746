@use '/src/mixin' as mx;
@use '/src/setting' as st;

.lab_order {
  .table {
    width: auto !important;


    &__data_0 {
      width: 105px;
      text-align: center;
    }

    &__data_1 {
      width: 253px;
      max-width: 253px;
    }

    &__data_2 {
      width: 107px;
      text-align: center;
    }

    &__data_3 {
      width: 105px;

      > div {
        @include mx.flex_box();
        gap: 4px;
        color: st.$danger_color;
      }
      .order_status__inner {
        margin: 0;
      }
    }

    &__data_4 {
      width: 61px;
      text-align: center;
    }

    &__data_5 {
      width: 74px;

      > div {
        margin: auto;
      }
    }

  }
}