@use '../../../mixin' as *;

.change_order_page_num {
  &__comment {
    .caution {
      line-height: 1.5;
      padding-left: 1em;
      text-indent: -0.9em;
    }
  }

  &__form {
    @include flex_all_center();
    gap: 20px;
    padding: 38px 0 8px 0;

    > div {
      &:first-of-type {
        @include flex_box;
        @include flex_align_center;
        .input {
          min-width: 50px;
          width: 50px;
          margin: 0 5px;
          border-radius: 2px;
          border-color: #707070;
        }
      }

      &:last-of-type {
        @include flex_box();
        @include flex_align_center();
        gap: 5px;

        .num_up_down {
          width: 80px;
          border-radius: 2px;
          border-color: #707070;

          &__handle {
            width: 1.3em;
          }
        }
      }

      &.triangle_icon {
        font-size: 9px;
        color: #707070;
      }
    }

    // .change_order_page_num__form
  }
}
