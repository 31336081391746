.info {
  margin: 0.5rem 0;
  min-width: 24rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label {
    display: block;
    margin-bottom: 0.2rem;
  }
}

.order-detail {
  display: flex;

  &__left,
  &__right {
    height: 480px;
    width: 22rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #EEE;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888888;
      border-radius: 8px;
      box-shadow: none;
    }
  }

  &__left {
    padding-right: 0.5rem;
  }

  &__right {
    padding: 0 2rem;
  }

  &__info {
    margin-bottom: 1rem;

    &__label {
      font-size: 0.8em;
      margin-bottom: 0.15rem;
    }

    &__val {
      font-size: 1em;
    }

    &__status {
      display: flex;
      align-items: center;

      .color_icon {
        margin-right: 0.3rem;
      }
    }
  }

  &__file_info {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-bottom: 1rem;
  }

  &__img_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4px;
      margin: 4px;
      width: 132px;
      border-radius: 4px;
      box-sizing: border-box;

      &__wrap {
        position: relative;
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
      }

      &.clickable {
        cursor: pointer;
      }

      &.prev-select {
        border: 1px solid rgba(213, 229, 243, 0.6);
      }

      label {
        font-size: 0.8em;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
        //width: 100%;
      }
    }
  }
}

.tree_view {
  width: 100%;

  .info {
    display: flex;
  }

  &__switch {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4B73E7;
    padding: 3px;
    border-radius: 3px;

    div {
      border-radius: 2px;
      width: 6rem;
      text-align: center;
      background-color: #FFF;
      cursor: pointer;
    }

    .active {
      background-color: #4B73E7;
      color: #FFF;
    }
  }

  .tree-view-cnt {
    min-height: 5rem;
    max-height: 15rem;
    overflow: auto;
  }
}

.c_order_detail__footer {
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.display_none {
  display: none !important;
}