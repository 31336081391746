@charset "UTF-8";
@use './setting' as st;
@use './mixin' as mx;

*,
::before,
::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  margin: 0 !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #888888;
  border: none;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #fff;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: none;
}

*:focus {
  outline: none !important;
}

.alert_message {
  color: st.$danger_color;
}

.emphasis_red {
  color: #d70b24;
}

.date_picker {
  width: 10rem;
}

/*form*/
textarea {
  @include mx.border-default();
  @include mx.radius(4px);
  width: 100%;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hidden {
  pointer-events: none;
  visibility: hidden;
}

/* margin */
.mr_0 {
  margin-right: 0 !important;
}

.mr_5 {
  margin-right: 5px !important;
}

.mr_10 {
  margin-right: 10px !important;
}

.mr_20 {
  margin-right: 20px !important;
}

.ml_5 {
  margin-left: 5px !important;
}

.ml_10 {
  margin-left: 10px !important;
}

.ml_auto {
  margin-left: auto !important;
}

.mt_10 {
  margin-top: 10px !important;
}

.mt_1rem {
  margin-top: 1rem !important;
}

.mt_2rem {
  margin-top: 2rem !important;
}

.mb_5 {
  margin-bottom: 10px !important;
}

.mb_10 {
  margin-bottom: 10px !important;
}

/* padding */
.py_0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px_2rem {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

/* gap */
.gap_3 {
  gap: 3px;
}

.gap_6 {
  gap: 6px;
}

.gap_col_8 {
  gap: 0 8px;
}

/* font */
.fs_12 {
  font-size: 12px;
}

/* line-height */
.lh_2 {
  line-height: 2;
}

/* flex */
.flex_box {
  @include mx.flex_box();
}

.flex_space_between {
  @include mx.flex_box();
  @include mx.flex_space_between();
}

.flex_direction_column {
  @include mx.flex_direction_column;
}

.flex_grow_1 {
  flex-grow: 1;
}

.column_direction_wrap {
  @include mx.flex_direction_column();
  height: 100%;
}

/* width */
.width_sm {
  // width: 80px;
  width: 8em;;
}

.width_md {
  width: 320px;
}

.width_lg {
  width: 560px;
}

/* 下線付きヘッダー */
.bottom_border_header {
  $border_height: 8px;
  padding: 0.5em 0;
  font-size: 1em;
  font-weight: bold;
  border-bottom: $border_height solid st.$ui_header_color;
}

.page_base {
  @include mx.flex_direction_column();
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.list_page_contents {
  @include mx.flex_direction_column();
  flex: 1;
  min-width: 600px;
  width: 90%;
  margin: auto;
  padding: 1rem 0 3rem;
  overflow: hidden;
}

.system_config {
  .top_label_ui {
    margin-top: 0.75rem;
  }
}

.table {
  tbody {
    td {
      .checkbox {
        width: 100%;
        @include mx.flex_box();
        @include mx.flex_content_center();
      }
    }
  }
}

/* プラスアイコン */
.plus_icon {
  @include mx.flex_all_center();
  position: relative;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    height: 0.2em;
    width: 1em;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(90deg);
  }
}

/* マイナスアイコン */
.minus_icon {
  @include mx.flex_align_center();
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    height: 0.2em;
    width: 1em;
    background-color: currentColor;
  }
}

.folders_tree {
  font-size: st.$font_size_small;
  width: 100%;

  &__item {
    @include mx.flex_box();
    @include mx.flex_align_center();
    gap: 0.25em;
    height: 1.5em;
    line-height: 1.5em;
    width: 100%;
    cursor: pointer;
    user-select: none;

    &__label {
      height: 100%;
      width: auto;
      flex: 1;
    }

    &:hover {
      color: #20a4f7;
      // color: st.$primary_color;
    }
    &__icon {
      @include mx.flex_box();
    }
  }
}

.detect_hover_h {
  height: 1.5px;
  width: 100%;
}

.bordered_left_label_item {
  @include mx.flex_box();
  border: 1px solid #707070;
  height: 3em;
  white-space: nowrap;

  > * {
    padding: 0 0.75em;
    line-height: calc(3em - 2px);
    text-align: center;
  }

  // ラベル
  > span {
    display: inline-block;
    background: #707070;
  }

  // 値
  > div {
    flex: 1;
  }
}

/* 三角形マーク デフォルトは▶︎この向き */
.triangle_icon {
  @include mx.flex_all_center();
  width: 16px;
  height: 16px;
  font-size: 6px;
  position: relative;

  &:before {
    display: block;
    content: '';
    border-top: 1em solid currentColor;
    border-left: calc(1em / 6 * 5) solid transparent;
    border-right: calc(1em / 6 * 5) solid transparent;
    transform: rotate(-90deg);
  }

  &_left {
    &:before {
      transform: rotate(90deg);
    }
  }

  &.bottom {
    &:before {
      transform: rotate(0deg);
    }
  }

  &.top {
    &:before {
      transform: rotate(180deg);
    }
  }
}

/* x軸展開・収納 */
.open_x_btn {
  min-width: 16px;
  min-height: 16px;
  width: 1.5em;
  height: 1.5em !important;
  padding: 0;

  img {
    width: 1em !important;
  }
}

.rect_icon {
  @include mx.flex_all_center();
  width: 24px;
  height: 24px;
  background-color: #333333;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}

.circle_icon {
  width: 1em;
  height: 1em;
  border: 1px solid st.$text_color;
  border-radius: 50%;
  background-color: transparent;

  &.blue,
  &.yellow,
  &.green {
    border-color: transparent;
  }

  &.blue {
    background-color: #4cdef2;
  }

  &.yellow {
    background-color: #fcf356;
  }

  &.green {
    background-color: #a2ef59;
  }
}

.close_icon {
  @include mx.flex_all_center();
  width: 1em;
  height: 1em;
  position: relative;
  color: st.$text_color;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 1em;
    height: 0.1em;
    background-color: currentColor;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.labeled_icon_hr {
  white-space: nowrap;
  @include mx.flex_box;
  @include mx.flex_align_center;
  gap: 0.25em;
}

/* チェックマーク */
.check_icon {
  &::before {
    display: block;
    content: '';
    width: 0.8em;
    height: 0.4em;
    border-left: 1px solid currentColor;
    border-bottom: 1px solid currentColor;
    position: absolute;
    transform: rotate(-45deg) translateY(-50%);
  }
}

.horizon_divider {
  height: 1em;
  width: 100%;
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

/* 色アイコン＋ステータス名 */
.order_status {
  display: inline-block;
  &__inner {
    @include mx.flex_box;
    @include mx.flex_align_center;
    gap: 0 0.5em;
    line-height: 1;
    margin: 0 auto;
  }
}