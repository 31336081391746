@import '../../mixin';
@import '../../setting';

.dialog {
  @include flex_all_center();
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  &.dimmer {
    z-index: 1000000;
    background-color: #00000080;
  }
  &.backDialog {
    z-index: 900000;
  }

  &__body {
    @include flex_direction_column();
    min-height: 185px;
    min-width: 376px;
    max-height: 90vh;
    max-width: 90vw;
    border: 1px solid #707070;
    position: absolute;
    z-index: 1;

    &__header {
      @include flex_box();
      @include flex_align_center();
      @include flex_space_between();
      background-color: $ui_header_color;
      color: #fff;
      padding: 0.5rem 12px;

      .close_icon {
        color: currentColor;
        cursor: pointer;
      }
    }

    &__contents {
      flex: 1;
      background-color: #fff;
      padding: 2rem;
      height: 0;
      overflow: hidden;
      font-size: 14px;

      /* 各ダイアログ内コンテンツ共通クラス 適宜クラス名付与 */
      .dialog_contents {
        @include flex_direction_column();
        height: 100%;
        max-height: calc(90vh - 6rem + 2px);

        &__body {
          $max_height: calc(90vh - 8rem - 2.5em + 2px);
          flex: 1;
          overflow: auto;
          max-height: $max_height;

          .table {
            width: auto;
            max-height: $max_height;
            border: 1px solid $table_border_color;

            tbody {
              tr {
                td {
                  font-size: $font_size_small;
                  padding: 0 1em;
                  // padding: 1em;
                }
              }
            }
          }

          &__comment {
            line-height: 1.5;
          }

          .input,
          .select {
            border-color: #707070;
            border-radius: 2px;
          }
        }

        &__footer {
          @include flex_box();
          margin-top: 2rem;

          .button {
            width: 80px;
            font-size: $font_size_small;
            padding: 0;
          }
        }

        &:not(.favorite_brand_config) {
          .dialog_contents {
            &__footer {
              @include flex_content_center();
              gap: 30px;

              //.button {
              //  margin: 0 15px;
              //}
            }
          }
        }
      }

      // dialog_contents
    }
  }
}
