.one-stop-image {
  // display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  background-color: #cccccc50;
  display: flex;
  align-items: center;
  justify-content: center;

  >img {
    width: 16em;
    height: 100%;
    object-fit: contain;
  }
}