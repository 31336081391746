@use '../../../mixin' as mx;
@use '../../../setting' as *;

.staff {
  padding: 0 26px;
  overflow: hidden;

  &__forms {
    @include mx.flex_direction_column;
    width: 100%;
    gap: 15px 0;

    .label_input {
      .choose_mail_label {
        opacity: 0.1;
      }

      .choose_mail_input {
        background-color: white;
      }
      .tooltip {
        max-width: 30rem;
      }

      .choose_id {
      }

      &__label {
        font-size: 100%;
        height: auto;
        line-height: 2em;
      }

      .input {
        min-width: 28em;
        width: 100%;
      }

      &__attention {
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .label_select {
      &__label {
        font-size: 100%;
        height: auto;
        line-height: 2em;
      }
      &__content{
        @include mx.flex_box;
        .radio {
          padding-right: 10px;
        }
      }
    }
    .label_button {
      @include mx.flex_box;
      @include mx.flex_align_center;
      .button {
        width: 40px;
        height: 24px;
        padding: 5px 10px;
        margin-left: auto;
      }
      .button__label {
        font-size: 25px;
        transform: scale(0.5);
      }
    }
  }
}
