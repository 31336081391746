@use '../../../_setting' as st;

.add_folder {
  font-size: st.$font_size_small;
  margin: 0 2rem;

  &__body {
    margin: 1rem 0;
    .top_label_ui {
      font-size: 100% !important;
      &__label {
        font-size: 100% !important;
      }
    }
  }

  &__message {
    color: st.$danger_color;
    margin-top: 1em;
  }
}
