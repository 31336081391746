@use '../../../mixin' as mx;

.select_img_layout_order {
  .dialog_contents__body {
    @include mx.flex_direction_column;
    gap: 1.5em 0;
  }
  
  &__switches {
    @include mx.flex_box;
    @include mx.flex_content_center;
    gap: 0 3em;
    .switch {
      border-radius: 2px;
      &__button {
        width: 6em;
      }
    }
  }
  .dialog_contents__footer {
    .button {
      width: 8em !important;
    }
  }
}
