@charset "utf-8";
@import './setting.scss';

@mixin fontFamily() {
  font-family: "メイリオ", "Meiryo", "Hiragino Sans", "ヒラギノ角ゴ Pro W4",
  "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", sans-serif !important;
}

@mixin radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin radiusDetails($radius1, $radius2, $radius3, $radius4) {
  -webkit-border-radius: $radius1 $radius2 $radius3 $radius4;
  border-radius: $radius1 $radius2 $radius3 $radius4;
}

@mixin border-default($size: 1px, $color: $border_default_color) {
  border: $size solid $color;
}

@mixin animationSlow() {
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

@mixin hoverOpa() {
  opacity: 0.6;
  @include animationSlow();
}

@mixin box_shadow($x: 0, $y: 3px, $blur: 6px, $spread: 0, $color: #00000029) {
  box-shadow: 0px $y $blur $spread $color;
}

@mixin box_shadow_inset($x: 0, $y: 0, $blur: 0, $spread: 4px, $rgba: rgba(20, 81, 161, 0.2)) {
  box-shadow: $x $y $blur $spread $rgba inset;
}

//テキスト選択無効
@mixin selectDisabled() {
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

//汎用input[api="text"]
@mixin inputText() {
  color: #4b4b4b;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  font-size: 12px;
  height: 25px;
  text-align: left;
  padding: 5px;
}

@mixin flex_box() {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex_align_start() {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin flex_align_stretch() {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@mixin flex_align_center() {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin flex_align_end() {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin flex_space_between() {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
}

@mixin flex_content_center() {
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

@mixin flex_content_end() {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
}

@mixin flex_content_start() {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

@mixin flex_content_around() {
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: distribute;
}

@mixin flex_container_wrap() {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

@mixin flex_direction_column() {
  @include flex_box();
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
}

@mixin flex_all_center() {
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
}

@mixin col_gap($gap: 1em) {
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

//ドラッグ禁止
@mixin dontDrag() {
  -webkit-user-drag: none;
}

$breakpoints-min: (
  'sm': 'screen and (min-width: 479px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 991px)',
  'xl': 'screen and (min-width: 1199px)',
) !default;

$breakpoints-max: (
  'xs': 'screen and (max-width: 320px)',
  'sm': 'screen and (max-width: 479px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 991px)',
  'xl': 'screen and (max-width: 1199px)',
) !default;

@mixin mq_min($breakpoint: md) {
  @media #{map-get($breakpoints-min, $breakpoint)} {
    @content;
  }
}

@mixin mq_max($breakpoint: md) {
  @media #{map-get($breakpoints-max, $breakpoint)} {
    @content;
  }
}

/* scroll 非表示 */
@mixin scroll_display_none {
  -ms-overflow-style: none; /* scroll 非表示 IE, Edge 対応 */
  scrollbar-width: none; /* scroll 非表示 Firefox 対応 */
  &::-webkit-scrollbar {
    display: none; /* scroll 非表示 chrome, safari */
  }
}

@mixin l_solid_r_flex($solid_width: 4em) {
  @include flex_box();
  div {
    &:first-of-type {
      width: $solid_width;
    }

    &:last-of-type {
      flex: 1;
      overflow-wrap: break-word;
    }
  }
}

/* 擬似要素用ボーダー */
@mixin pseudo_hor_border($height: 1px, $width: 100%, $color: $border_secondary) {
  content: '';
  display: block;
  height: $height;
  width: $width;
  background-color: $color;
  position: absolute;
  left: 0;
  /* 上下は使用箇所で個別設定 */
}

//3点リーダー
@mixin ellipsis {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis_nth_clamp($clamp) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $clamp;
  overflow: hidden;
  /*
    clamp の値以上の行数の箇所へ適用すると、一部 hidden から漏れることがあるので、
    その際は height か line-height などを調整して隠す必要あり。
  */
}

@mixin scrollbar_no_track($color: #888888) {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: $color;
    border-radius: 4px;
    box-shadow: none;
  }
}

@mixin scrollbar_radius_track {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #888888;
    border: none;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 4px;
    box-shadow: none;
  }
}

@mixin grid() {
  display: -ms-grid;
  display: grid;
}

@mixin grid_repeat_fill($minCol: 20em) {
  grid-template-columns: repeat(auto-fill, minmax($minCol, 1fr));
}

@mixin triangle_chip($color: #fff9d9) {
  color: $color;
  display: block;
  content: '';
  border-top: 1em solid currentColor;
  border-right: 1em solid currentColor;
  border-bottom: 1em solid transparent;
  border-left: 1em solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@mixin appearance_none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
