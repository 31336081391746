.one-stop-image-cnt {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  transition: all .3s ease-out;
  height: 240px;

  &.open {
    height: 100%;

    .one-stop-image-wrap {
      height: 100% !important;
    }
  }

  >h2 {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .one-stop-image-wrap {
    margin-top: 10px;
    width: 100%;
    height: 210px;
    overflow-y: auto;
    font-size: 14px;

    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  .one-stop-image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 16em);
    grid-template-rows: 150px;
    gap: 1rem;
    row-gap: 2rem;
  }
}