@use '../../../../mixin' as mx;
@use '../../../../setting' as st;

.color_dropper {
  width: 100%;
  height: 100%;
  @include mx.flex_box;
  @include mx.flex_direction_column;
  gap: 0.5rem;
  overflow: hidden;

  &__header {
    background-color: #707070;
    padding: 0.5rem;
  }

  &__color {
    @include mx.flex_box;
    @include mx.flex_align_center;
    min-height: 2.5em;
    height: 2.5em;
    gap: 0 8px;

    &__chip {
      width: 4.5em;
      height: 100%;
    }
    &__code {
      color: #fff;
      width: 6em;
      height: 100%;
      @include mx.flex_all_center;
      background-color: #ffffff4d;
    }
  }

  &__canvas {
    width: 100%;
    height: 100%;
    overflow: auto;
    @include mx.flex_all_center;
    canvas {
      // max-width: 100%;
      // max-height: 100%;
      max-width: calc(90vw - 2rem);
      max-height: calc(90vh - 5rem);
      object-fit: contain;
    }
  }
}
