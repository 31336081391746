.guid_list {
  width: 31.6em;
  .table {
    min-width: 31.6em;
    width: 100% !important;
    &__head_0 {
      width: 38px;
    }
    &__head_1 {
      width: 46px;
    }
    &__head_2 {
      width: 74px;
    }
    &__head_3 {
      width: 72px;
    }

    &__data_1 {
      text-align: right;
    }
    &__data_2,
    &__data_3 {
      text-align: center;
    }
    &__data_4 {
      .button {
        margin: auto;
      }
    }
  }
}
