@use "../../../setting" as *;
@use "../../../mixin" as mx;

.select {
  font-family: inherit;
  font-size: 100%;
  width: 100%;
  border: 1px solid $form_border_color;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  height: 3.2em;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0.1em;
    right: 1em;
    margin: auto;
    content: '';
    width: 0.5em;
    height: 0.5em;
    border-left: solid 2px currentColor;
    border-top: solid 2px currentColor;
    transform: rotate(-135deg);
    cursor: pointer;
    pointer-events: none;
  }

  &.error {
    border: 1px solid $form_error_border_color;
    background-color: $form_error_bg_color;

    select {
      color: $form_error_text_color;
    }
  }

  select {
    font-size: 100%;
    @include mx.flex_box();
    @include mx.flex_align_center();
    height: 100%;
    width: 100%;
    padding: 0 2em 0 0.5em;
    cursor: pointer;
    text-overflow: ellipsis;
    border: none;
    border-radius: inherit;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(143, 143, 143, 0.05);

    &::-ms-expand {
      display: none;
    }

    &:disabled {
      color: black;
      opacity: 1;

    }
  }
}
