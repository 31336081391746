@use '../../../mixin' as *;

.sidebar {
  @include flex_direction_column();
  padding: 1rem 2.5rem;
  height: 100%;

  &__return {
    @include flex_box();
    margin-bottom: 2rem;
    font-size: $font_size_tiny;
    // font-size: $font_size_small;
    cursor: pointer;

    img {
      margin-right: 0.5em;
    }
  }

  &__contents {
    @include flex_direction_column();
    flex: 1;
    width: 15em;

    &__title {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    &__item {
      &:not(:first-of-type) {
        margin-top: 1rem;
      }

      .item_label {
        font-size: $font_size_tiny;
      }

      .item_body {
        margin-top: 0.5rem;
        font-size: $font_size_small;
        font-weight: 500;

        &__type_store {
          @include flex_box;
          @include flex_align_center;
          gap: 0.5em;

          .button {
            font-size: 12px;
            height: 3em;
            min-width: 7.5em;
            margin-top: 0 !important;
          }
        }

        .input,
        select {
          height: 3.2em;
        }

        textarea {
          // font-size: 10px;
        }
      }

      .redBox {
        div {
          border: #d70b24 0.5px solid;
        }
      }

      /* 個別設定 */
      &.item_customer {
        &.no_name {
          z-index: 11;
        }

        .item_body {
          @include flex_box;
          @include flex_align_center;
          gap: 0.5em;
          .input {
            //width: 10em;
          }
        }
      }

      &.item_status {
        .labeled_icon_hr {
          font-weight: bold;
          &.error {
            color: $danger_color;
          }
        }
      }

      &.item_memo {
        @include flex_direction_column();
        flex: 1;

        //.item_label{
        //  font-size: 10px;
        //}

        .item_body {
          flex: 1;

          textarea {
            min-height: 4em;
            height: 100%;
            max-height: 24em;
          }
        }
      }

      &.item_delivery {
        .button {
          margin-top: 0.5rem;
        }
      }

      // sidebar__contents__item
    }

    // sidebar__contents
  }

  &__disable_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.2);
    z-index: 10;
    top: 0;

    // .sidebar__disable_bg
  }

  // .sidebar__disable_bg
}
