@use '../../../../mixin' as mx;

.trimming_controller {
  position: fixed;
  z-index: 1;
  font-size: 0.875rem;
  width: 23em;
  // height: 55.5em;
  background-color: #383838;
  margin: 0;

  &__body {
    padding: 1em;
    @include mx.flex_direction_column;
    gap: 0.5em;

    &__top {
      @include mx.flex_direction_column;
      gap: 1.25em;
      // .trimming_controller__body__top
    }

    &__middle {
      @include mx.flex_direction_column;
      gap: 1.25em;
      &__forms {
        @include mx.flex_direction_column;
        gap: 1em;
      }
      // .trimming_controller__body__middle
    }

    &__bottom {
      @include mx.flex_direction_column;
      gap: 0.5em;
      &__row {
        @include mx.flex_box;
        &:nth-of-type(2) {
          gap: 0.5em;
        }
      }
      // .trimming_controller__body__bottom
    }
    // .trimming_controller__body
  }

  &__grab {
    background-color: #606060;
    height: 1.25em;
    @include mx.flex_all_center;
    cursor: grab;

    &__icon {
      width: 0.75em;
      height: 0.75em;
      background-color: #b0b0b0;
    }
  }

  &__form {
    @include mx.flex_box;
    @include mx.flex_align_center;
    @include mx.flex_space_between;
    span {
      white-space: nowrap;
    }
  }

  .button {
    height: 3em;
    width: 100%;
    font-weight: normal;
  }

  .num_up_down,
  .input {
    width: 7.5em;
    height: 3em !important;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 0;
    color: #fff;

    &:focus {
      border-color: unset;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .num_up_down {
    input {
      height: 100%;
    }
    &__handle {
      .angle_icon {
        color: currentColor !important;
      }
    }
  }
  // .trimming_controller
}
