@use '../../../setting' as *;
@use '../../../mixin' as mx;

.input {
  font-size: 100%;
  position: relative;
  border-radius: 4px;
  min-width: 80px;
  width: 320px;
  border: 1px solid $form_border_color;
  background-color: #fff;

  &__form {
    font-family: inherit;
    font-size: 100%;
    color: inherit;
    padding: 0 0.5em;
    width: 100%;
    background-color: transparent;
    height: 100%;
    border: none;
    border-radius: inherit;
  }

  &:focus {
    border-color: $form_focus_color;
    @include mx.box_shadow;
  }

  &::placeholder {
    color: $placeholder_color;
  }

  &.error {
    border-color: $danger_color;
    @include mx.box_shadow($y: 0, $blur: 3px, $color: rgba($danger_color, 0.6));
  }

  &.disabled {
    background-color: #4b73e71a;
    opacity: 1;
    input {
      color: black;
      //font-weight: bold;
    }
  }

  &.sm {
    height: 24px;
  }

  &.md {
    height: 32px;
  }

  &.lg {
    height: 48px;
  }

  &.custom_date {
    $width: 300px;
    width: $width !important;
    min-width: $width !important;
    @include mx.flex_box;

    .fa-calendar {
      width: 2em;
      height: 100%;
      @include mx.flex_box;
      @include mx.flex_all_center;
      overflow: hidden;
      position: relative;
      
      input[type='date'] {
        font-size: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 5px;
        width: 2em;
        height: 100%;
        padding: 0;
      }
    }
  }
}

input {
  &[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }
}
