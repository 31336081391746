@use '../../../../mixin' as mx;
@use '../../../../setting' as *;

.tool_view_list {
  min-width: 11em;
  width: auto;
  height: 100%;
  background-color: #000;
  font-size: $font_size_tiny;

  &.draggable {
    .tool_contents__inner {
      position: relative;
      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background-color: cyan;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        animation-name: draggable_animation;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
      }
    }
  }

  .tool_contents__inner {
    @include mx.flex_direction_column;
    padding: 0.5em;
    gap: 0.5em;
  }

  &__header {
    @include mx.flex_direction_column;
    gap: 0.25em;
    text-align: center;

    .button {
      height: 2em !important;
      font-size: $font_size_tiny;
    }

    &__btn {
      height: 2em;
      @include mx.flex_all_center;
      white-space: nowrap;
      background-color: #606060;
      border-radius: 2px;
      gap: 0.5em;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #0000004d;
      }

      // レイアウト選択ボタン
      &.ly {
        width: 50%;
        gap: 0.125em;

        &.selected {
          background-color: #0000004d;
        }
      }

      // ソート
      &.sort {
        position: relative;
      }

      img {
        max-height: 1em;
      }
    }
  }

  &__body {
    $col_width: 11em;
    gap: 0.5em;
    overflow-y: auto;

    &.one_col {
      @include mx.flex_direction_column;
      width: $col_width;

      img {
        width: 100%;
      }
    }
    &.two_col {
      width: calc(#{$col_width} * 2);
      @include mx.flex_box;
      @include mx.flex_container_wrap;

      .tool_view_list__body__thumb {
        max-width: calc(100% / 2 - 0.25em);
        img {
          width: 100%;
        }
      }
    }

    &__thumb {
      width: 100%;
      @include mx.flex_direction_column;
      @include mx.flex_content_center;
      cursor: pointer;

      &.dummy_thumb {
        &::before {
          display: block;
          content: '';
          padding-top: 100%;
        }
        position: relative;
        @include mx.flex_box;
        @include mx.flex_align_center;
        @include mx.flex_content_center;
        background: #fff;
        .loading_popup__dots {
          position: absolute;
        }
      }

      &__img {
        position: relative;
        img {
          border: 2px solid #fff;
          border-bottom: none;
        }
      }

      &__info {
        font-size: 10px;
        color: #383838;
        text-align: center;
        padding: 0.25em;
        background-color: #fff;

        > div {
          line-height: 1.3;
          &:first-of-type {
            word-break: break-all;
          }
          &:last-of-type {
            @include mx.ellipsis;
          }
        }
        // tool_view_list__body__thumb__info
      }

      &__check {
        @include mx.flex_all_center;
        width: 2.5em;
        height: 2.5em;
        background-color: rgba($color: #000, $alpha: 0.5);
        position: absolute;
        top: 2px;
        right: 2px;
        border-radius: 50%;

        &::before {
          display: block;
          position: absolute;
        }
        &:not(.delete) {
          left: 2px;
          &::before {
            $circle_size: 1.85em;
            content: '';
            width: $circle_size;
            height: $circle_size;
            border: 0.2em solid #fff;
            border-radius: 50%;
          }
        }

        &.delete {
          &::before {
            content: '\0d7';
            font-size: 2.1em;
            top: 0;
            bottom: 0;
            line-height: 1.1;
          }
          &:hover {
            background-color: rgba(149, 149, 149, 0.8);
          }
        }
        // .tool_view_list__body__thumb__check
      }
      .image_upload_status {
        top: initial;
        bottom: 2px;
      }
      // tool_view_list__body__thumb
    }
    img {
      object-fit: contain;
    }

    // .tool_view_list__body
  }

  &__guidance {
    flex: 1;
    @include mx.flex_direction_column;
    @include mx.flex_content_center;
    @include mx.flex_align_center;

    &.one_col {
      span {
        max-width: 10em;
      }
    }
    &.two_col {
      span {
        max-width: 16em;
      }
    }
    img {
      width: 6em;
    }
    opacity: 0.6;

    // .tool_view_list__guidance
  }

  // .tool_view_list
}

// ソートメニュー
.sort_menu {
  font-size: $font_size_tiny;
  width: 11em;
  position: absolute;
  top: 0;
  // right: 0;
  // transform: translateX(100%);
  left: calc(100% + 1em - 6px);
  background-color: #383838;
  padding: 0.5em;
  @include mx.flex_direction_column;
  gap: 5px 0;

  &__item {
    padding: 0 1em;
    height: 1.5em;
    line-height: 1.5;
    background-color: #606060;
    border-radius: 0.25em;
    cursor: pointer;
    position: relative;

    &:hover,
    &.selected {
      background-color: #0000004d;
    }

    &.selected {
      &::before {
        display: block;
        content: '';
        width: 0.8em;
        height: 0.4em;
        border-left: 1px solid currentColor;
        border-bottom: 1px solid currentColor;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0.5em;
        margin: auto;
        transform: rotate(-45deg) translateY(-50%);
      }
    }
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: #fff;
  }
}

@keyframes draggable_animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

/* 拡大画像表示用モーダル */
.img_modal_display {
  .dialog__body__header {
    background-color: #fff;
    padding: 1rem;
    .close_icon {
      width: 2em;
      height: 2em;
      background-color: #00000080;
      border-radius: 2px;
      padding-bottom: 1px; // 見た目上の微調整
      margin-left: auto;
    }
  }
  .dialog__body__contents {
    padding-top: 0;
    .dialog_contents {
      &__body {
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          max-height: calc(90vh - 10rem);
          max-width: calc(90vw - 4rem);
          object-fit: contain;
        }
      }
    }
  }
}
