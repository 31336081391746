@use '../../../mixin' as mx;
@use '../../../setting' as *;

.type_select_button {
  @include mx.flex_all_center;
  font-size: $font_size_small;
  padding: 1px 1em;
  height: 2em;
  cursor: pointer;
  white-space: nowrap;

  &.current {
    background-color: $primary_color;
    color: #fff;
    font-weight: bold;
    border-radius: 1em;
  }
}

.c_order_list_table {
  @include mx.flex_direction_column;
  flex: 1;
  overflow: hidden;

  &__type_select {
    overflow-x: auto;
    padding-bottom: 2px;
    @include mx.scrollbar_no_track;

    > div {
      @include mx.flex_box;
      @include mx.flex_align_center;
      // min-width: 855px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      .button {
        background: none;
        color: $primary_color;
        height: 24px;
        font-weight: normal;
      }
    }
  }

  &__menu_btn {
    @include mx.flex_box;
    justify-content: space-between;
    margin-top: 0.5rem;

    &__search {
      display: flex;

      .input {
        height: 35px;
      }
    }

    .button {
      padding: 1px 1rem;
      margin-left: 0.5rem;

      .button__icon {
        height: 1.3em;
      }
    }

    .proxy_mode_form {
      font-size: 1rem;
      font-weight: bold;
      @include mx.flex_box;
      gap: 0 0.5em;

      &__label {
        > div {
          &:last-of-type {
            font-size: 0.75em;
          }
        }
      }

      .input {
        width: 10em;
        height: 2.2rem;
      }
    }
  } // order_table__menu_btn

  .status_view {
    display: flex;
    align-items: center;
    width: fit-content;

    .color_icon {
      margin-right: 0.3em;
    }
  }

  &__table {
    flex: 1;
    margin-top: 0.5rem;
    overflow: hidden;

    .table {
      padding-right: 1px; // スクロールバーとの間隔調整用
      //&__head_0 {
      //  width: 5rem;
      //  min-width: 5rem;
      //}

      &__head_3 {
        width: 25%;
        min-width: 8rem;
      }

      &__head_1 {
        width: 13%;
      }

      &__head_0,
      &__head_2,
      &__head_4,
      &__head_5,
      &__head_6 {
        width:fit-content;
        min-width: 6rem;
      }

      &__head_7 {
        width: 7%;
        min-width: 3rem;
      }

      &__data_0,
      &__data_1,
      &__data_4,
      &__data_5,
      &__data_6,
      &__data_7 {
        text-align: center;
      }

      &__data_1 {
        div {
          margin: 0 0.5em;
        }
      }

      &__data_7 {
        .button {
          margin: auto;
        }
      }
    }
  }

  .table {
    table {
      width: 100%;
      font-size: $font_size_small;
      background-color: #fff;
      border-collapse: collapse;

      th,
      td {
        white-space: nowrap;
        padding: 5px 10px;
      }

      .checkbox {
        &__form {
          background-color: #fff;

          &.checked {
            border-color: currentColor;
          }
        }
      }

      thead {
        height: 3em;
        position: sticky;
        top: 0;
        z-index: 1;

        th {
          background-color: $ui_header_color;
          color: #fff;
          font-weight: normal;
          text-align: center;

          position: relative;

          &::before {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            border-left: 1px solid #fff;
            position: absolute;
            top: 0;
            left: -1px;
          }
        }
      }
    }
  }
}

.status_filter {
  @include mx.flex_box;
  @include mx.flex_align_center;
  font-size: clamp(0.7rem, 1.5dvw, 1rem);
  margin-bottom: 0.2rem;

  &__content {
    @include mx.flex_box;
    @include mx.flex_align_center;
    @include mx.flex_content_center;
    padding: 0 clamp(0.6rem, 1.6vw, 2rem);
    white-space: nowrap;
    height: 1.6rem;
    border-radius: 50px;
    cursor: pointer;
  }

  &__select {
    color: #FFF;
    background-color: #4B73E7;
    font-weight: bold;
  }

  &__search_btn {
    .button {
      background: none;
      color: $primary_color;
    }
  }
}

.purpose_filter {
  @include mx.flex_box;
  flex-wrap: wrap;

  &__content {
    margin-right: 1rem;

    label {
      font-size: clamp(0.7rem, 1.7dvw, 1rem);
      white-space: nowrap;
    }
  }
}
