@use '../../../../mixin' as mx;
@use '../../../../setting' as *;

.layout_toolbar {
  font-size: $font_size_small;
  @include mx.flex_box;
  background-color: $layout_page_category_bg_color;
  height: 100%;
  position: relative;
  z-index: 51;

  &__tools {
    @include mx.flex_direction_column;
    padding: 5px;
    gap: 5px;
    overflow-y: auto;

    &.simple {
      .tool_button {
        min-width: auto;
        width: auto;
      }
    }

    .tool_button {
      min-width: 11em;
      width: 11em;
      padding: 0.5em;
      height: auto;
      min-height: 30px;
      @include mx.flex_content_start;
      
      &:active {
        opacity: 0.5;
      }
      &:not(.simple) {
        // justify-content: start;
        .button__icon {
          margin-right: 0.5em !important;
        }
      }

      &.selected {
        background-color: rgba($color: #595959, $alpha: 0.25);
        box-shadow: 0 0 0 1px inset rgba($color: #fff, $alpha: 0.2);
      }
      .button__icon {
        height: 1.8em !important;
      }
    }
  }

  .tool_contents {
    padding-left: 6px;
    &__inner {
      background-color: $layout_page_category_bg_color;
      height: 100%;
    }
  }
}
