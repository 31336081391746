@import '../../../mixin';

.loading_bar {
  &__dimmer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex_all_center;
  }

  &__wrap {
    @include flex_direction_column;
    @include flex_align_center;
    gap: 5px 0;
  }

  &__message {
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
    text-align: center;
  }
}
