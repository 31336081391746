@use '../../../mixin' as mx;
@use '../../../setting' as *;

.wizard {
  height: 100%;

  &__contents {
    @include mx.flex_all_center();
    flex: 1;
    padding: clamp(1rem, 2.5vw, 3rem);
    height: 88%;

    &__inner {
      position: relative;
      flex-direction: column;
      height: 600px;
      display: flex;

      &__title {
        @include mx.flex_all_center();
        height: 100px;
        font-size: 1.5rem;
        font-weight: 500;
      }

      &__subtitle {
        @include mx.flex_all_center();
        height: 60px;
        font-size: 0.9rem;
      }

      &__step-bar {
        @include mx.flex_all_center();
        height: 120px;
        &__bridge {
          position: relative;
          width: 80px;
          height: 2px;

          &.past {
            background-color: $primary_color;
          }

          &.future {
            background-color: gray;
            opacity: 0.5;
          }
        }

        &__number-circle {
          border-radius: 50%;
          color: white;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 19px;

          &.current {
            background-color: $primary_color;
            border: 1.3px solid;
            box-shadow: 0 0 0 3px $primary_color;
          }

          &.past {
            background-color: $primary_color;
          }

          &.future {
            background-color: gray;
            opacity: 0.5;
          }
        }
      }

      &__body {
        @include mx.flex_all_center();
        height: 150px;
        flex-direction: column;
        gap: 3rem;
        text-align: center;
      }

      &__notice {
        height: 100px;
        flex-direction: column;
        padding: 2rem 4rem;
        font-size: 0.9rem;
        color: #d70b24;
      }

      &__input {
        @include mx.flex_all_center();
        flex-direction: column;
        gap: 2rem;
        &__wrapper {
          max-width: 320px;
          &__label {
            font-size: 0.9rem;
            padding-bottom: 0.5rem;
          }
          &__desc_top {
            font-size: 0.9rem;
            padding-bottom: 1.5rem;
            white-space: nowrap;
          }
          &__desc_bottom {
            font-size: 0.8rem;
            padding-top: 0.5rem;
            white-space: nowrap;
          }
        }
      }

      &__button {
        @include mx.flex_all_center();
        gap: 2rem;
        margin-top: auto;
      }
    }
  }
}