@use '../../../mixin' as mx;
@use '../../../setting' as *;

.detail_search {
  padding: 0 26px;
  overflow: hidden;

  &__body {
    font-size: $font_size_small;
    @include mx.flex_direction_column;
    @include mx.flex_align_center;
    gap: 30px 0;
    width: 100%;
    max-width: 23.8em;
  }

  &__forms {
    @include mx.flex_direction_column;
    width: 100%;
    gap: 15px 0;

    &__date {
      &__mode {
        // @include mx.flex_box;
      }
    }

    .date_start_end {
      @include mx.flex_container_wrap;
      row-gap: 0.35em;
      .input {
        min-width: 10.1em;
        max-width: 10.7em;
      }
    }

    .top_label_ui {
      &__label {
        font-size: 100%;
        height: auto;
        line-height: 2em;
      }

      .input {
        max-width: 23.85em;
        // max-width: 320px;
        width: 100%;
      }

      &.order_number {
        .input {
          max-width: 10.714em;
        }
      }
    }
  }
}
