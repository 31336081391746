@import '../../../mixin';

.order_delete_confirm {
  height: 100%;
  padding: 0 30px;
  overflow: hidden;
  &__body {
    flex: 1;
    overflow: hidden;
    @include flex_direction_column;
    gap: 30px 0;
  }
  .table {
    flex: 1;
    border: none !important;

    &__head_0 {
      min-width: 4em;
    }
    &__head_1 {
      min-width: 9.2em;
    }
    &__head_2 {
      min-width: 12em;
      max-width: 32em;
    }
    &__head_3 {
      min-width: 11.2em;
    }
    &__head_4 {
      min-width: 7.2em;
    }
    &__head_5 {
      min-width: 8.2em;
    }

    td {
      text-align: center;
    }
  }
}
