@use '../../../../mixin' as mx;
@use '../../../../setting' as *;

/* テキスト追加UI */
.free_text_ui {
  $row_base_height: 2.4rem;
  font-size: 1em;
  position: absolute;
  left: 3em;
  width: 80%;
  min-width: 43em;
  max-width: 930.5px;
  @include mx.flex_direction_column;
  background-color: #fff;
  border: 0.5em solid #e2e2e3;
  padding: 0.5em;
  color: #4d4d4d;
  gap: 0.5em;

  &__dimmer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    // free_text_ui__dimmer
  }

  .button,
  .select,
  .color_picker,
  .left_label_form {
    height: $row_base_height;
  }

  .button,
  .color_picker {
    min-width: $row_base_height;
    width: $row_base_height;
    border-radius: 0.2rem;
    border-color: #4d4d4d;
  }

  .button {
    padding: 0;

    &.btn_bold,
    &.btn_italic,
    &.btn_delete,
    &.btn_reload {
      color: currentColor;
    }

    &.btn_bold,
    &.btn_italic {
      font-size: 1.1rem;
    }

    &.btn_delete {
      font-size: 1.01rem;
    }

    &.btn_reload {
      font-size: 1.4rem;
    }
  }

  .select {
    &::before {
      right: 0.5em;
    }
  }

  .checkbox {
    white-space: nowrap;

    &__form {
      font-size: 1.6em;
      border-radius: 4px;
      border-color: #888888;
    }
  }

  .left_label_form {
    > span {
      &:not(.form_unit) {
        white-space: nowrap;
        width: auto;
        min-width: auto;
        padding: 0 0.8em;
        background-color: #4b73e733 !important;
      }
    }

    .num_up_down,
    .select {
      border: 1px solid #e0e0e0;
      width: 6em;
      height: 100%;
    }
  }

  .color_picker {
    border: 1px solid;
    padding: 1px;

    > .button {
      min-width: 0;
      width: 100%;
      height: 100%;
    }
  }

  .row1,
  .row2,
  .row3 {
    width: 100%;
    line-height: 2.4em;
    @include mx.flex_box;
    @include mx.flex_container_wrap;
    gap: 0.5em;

    &__col1,
    &__col2,
    &__col3 {
      @include mx.flex_box;
      @include mx.flex_align_center;
    }

    &__col1 {
      min-width: 13em;
    }

    &__col2 {
      // min-width: 24em;
      // width: 24em;
    }

    &__col3 {
      // flex: 2;
      // min-width: 26em;
    }
  }

  .row1 {
    @include mx.flex_align_start;

    &__col1 {
      gap: 0.5em;

      .button {
        &:nth-of-type(2) {
          font-style: italic;
        }
      }
    }

    &__col2 {
      flex: 1;
      gap: 0.3em;

      .left_label_form {
        gap: 0.5em 1em;
        @include mx.flex_container_wrap;
        height: auto;
        width: 100%;

        > span {
          height: $row_base_height;
        }

        > div {
          @include mx.flex_box;

          &:first-of-type {
            gap: 0.5em;

            .radio {
              label {
                &:after {
                  background-color: $primary_color;
                }
              }
            }

            .local_font_wrap {
              position: relative;

              .local_font_alert {
                padding-right: 0.75em;
                padding-left: 0.5em;
                background-color: #fff8d4;
                color: #d70b24;
                transform: translateX(-45%);
              }
            }
          }

          &:last-of-type {
            flex: 1;
            gap: 0.3em;
          }
        }
      }

      .font_size {
        position: relative;
        width: 84px;

        .input {
          width: 60px;
          border: none;
          z-index: 1;
        }

        .select {
          width: 84px;
          position: absolute;
          top: 0;
          left: 0;

          select {
            color: #00000000;

            option {
              color: mx.$font-dark;
            }
          }
        }
      }

      //.select {
      //  border-radius: 0;
      //
      //  &:first-of-type {
      //    min-width: 16.6em;
      //    width: 16.6em;
      //  }
      //
      //  &:last-of-type {
      //    min-width: 6em;
      //    width: 6em;
      //    flex: 0;
      //  }
      //}
    }
  }

  .row2 {
    @include mx.col_gap;

    &__col1 {
      .left_label_form {
        span {
          &:not(.form_unit) {
            width: 6.8em;
          }
        }
      }
    }

    &__col2 {
      flex: 1;
      gap: 1em;
    }
  }

  .row3 {
    @include mx.col_gap($gap: 1.9em);

    &__col1 {
      .left_label_form {
        span {
          &:not(.form_unit) {
            width: 6.8em;
          }
        }
      }
    }

    &__col2 {
      flex: 1;

      .left_label_form {
        gap: 0.3em;
      }
    }

    &__col3 {
      gap: 0.5em;

      .left_label_form {
        > span {
          font-size: calc(1em - 2px);
        }

        + .left_label_form {
          flex: 1;
          @include mx.flex_box;
          @include mx.flex_space_between;

          > span {
            // flex: 1;
            white-space: nowrap;
            @include mx.flex_content_start;
          }
        }
      }
    }
  }

  .row4 {
    .textarea {
      height: 6em;
    }
  }

  &__footer {
    @include mx.flex_all_center;
    @include mx.flex_space_between;
    gap: 0.5em;

    &__right {
      @include mx.flex_box;
      gap: 0.5em;
    }

    .button {
      font-size: 100% !important;
      height: 2.5em;
      min-width: 7em;
      width: 7em;
    }
  }
}

.dropper_dialog {
  min-height: auto !important;
  background-color: #000;
  padding: 0.5rem;
  gap: 0.5rem 0;

  .dialog__body__header {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    padding-right: 1.5rem;
    height: 4em;
  }

  .dialog__body__contents {
    padding: 0;
    background-color: transparent;
  }
}

.loading_font_dialog {
  border-radius: 0.25rem;

  .dialog__body__header {
    display: none;
  }

  .dialog__body__contents {
    @include mx.flex_all_center;
    border-radius: inherit;

    > div {
      @include mx.flex_box;
      gap: 2px;

      .awaiting_ellipsis {
        // @include mx.flex_align_end;
      }
    }
  }
}
