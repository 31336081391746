@use '../../../mixin' as *;

.layout {
  $column_width: 18rem;
  .layout_category {
    border: 1px solid #707070;
    padding: 4px;
    @include flex_direction_column;

    &__header {
      background-color: #747474;
      color: #f7f7f7;
      text-align: center;
      height: 22px;
      line-height: 22px;
    }

    &__body {
      padding: 6px;
      flex: 1;
      overflow: auto;

      &.tree_view {
        height: 100%;
      }

      .sort_boundary {
        &.dummy {
          cursor: default;
          .sort_boundary__line {
            // opacity: 0;
            visibility: hidden;
          }
          .folders_tree__item {
            cursor: default;
          }
        }
      }
    }

    // 個別
    &.layout_all_template {
      .layout_category__body {
        padding-right: 0;
      }
    }
    .folders_tree__item__label {
      &.hover {
        color: #20a4f7;
      }
    }
  }

  &__body {
    @include flex_box();
    flex: 1;
    overflow: hidden;
    overflow-x: auto;
    padding: 6px;
    gap: 6px;
    color: #f7f7f7;

    &__templates {
      background-color: $category_bg_color;
      /* (新しめの)Chrome の font-size の下限が 10px のため余白等の見た目を揃えるためにデザインよりも大きい値 */
      min-width: $column_width;
      width: $column_width;
      padding: 6px;
      @include flex_direction_column();
      gap: 5px;
      overflow-y: auto;
      > * {
        &:not(:first-of-type) {
          flex: 1;
          overflow-y: hidden;
          min-height: 230px;
        }
      }
    }

    &__select,
    &__edit {
      flex: 1;
      min-width: calc(#{$column_width} * 2);
    }
    &__select {
      overflow-y: auto;
    }

    &__edit {
      @include scroll_display_none;
      overflow: hidden;
      position: relative;
      .trimming_controller {
        @include flex_direction_column;
        position: absolute;
        right: 0;
        z-index: 99999;
        max-height: 770px;
        overflow: hidden;

        @media screen and (max-height: 1024px) {
          font-size: clamp(12px, 1.385vh, 14px);
          height: 91%;
          *:not(.angle_icon) {
            font-size: 100%;
          }
        }

        &__body {
          flex: 1;
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }

    &__preview {
      min-width: $column_width;
      width: $column_width;  
    }
    // .layout__body
  }
  // .layout
}
