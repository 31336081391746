@use '../../../setting' as *;
@use '../../../mixin' as mx;

.print_order_image {
  overflow: hidden;

  &__body {
    flex: 1;
    @include mx.flex_direction_column;
    overflow: hidden;

    &__menu {
      .button {
        // width: 80px;
        font-size: $font_size_tiny;
        width: 10em;

        + .button {
          margin-left: 8px;
        }
      }
    }

    &__list {
      flex: 1;
      overflow: hidden;
    }
  }
}

.print_page {
  height: 100%;
  width: 100%;
  // overflow: auto;
  .contact_sheet {
    height: auto;
  }
}