@use "../../../mixin" as mx;
@use "../../../setting" as *;

.favorite_brand_config {
  font-size: $font_size_small;
  width: 100%;
  overflow: hidden;
  max-height: initial !important;

  &__ancestor {
    min-height: 35.5rem !important;
  }

  &__table {
    $table_height: 25rem; 
    min-height: $table_height !important;
    max-height: $table_height !important;

    &__inner {
      min-width: 50em;
      height: max-content;
      max-height: inherit;
      @include mx.flex_direction_column;
      border: 1px solid #888888;
      overflow: auto;
      @include mx.scrollbar_no_track;  
    }

    &__header {
      background-color: $ui_header_color;
      color: #fff;
      text-align: center;
      height: 2em;
      line-height: 2;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &__body {
      flex: 1;
      width: 100%;

      &__row {
        @include mx.flex_box;
        @include mx.flex_align_center;
        color: #0b0c0e;
        gap: 0.25em;
        height: 2.5em;
        padding: 0.5em;
        cursor: pointer;

        &:nth-of-type(2n) {
          background-color: $table_td_even_color;
        }

        &:nth-of-type(2n + 1) {
          background-color: $table_td_odd_color;
        }

        &.selected {
          background-color: $table_selected_color;
        }

        &__icon {
          @include mx.flex_box;
        }

        &__data {
          white-space: nowrap;
          @include mx.ellipsis;
        }
      }
    }
  }

  .dialog_contents__footer {
    @include mx.flex_space_between;
    @include mx.flex_container_wrap;
    gap: 1em;

    > div {
      flex: 1;
    }

    .button {
      width: 7em !important;
    }

    &__center {
      @include mx.flex_box;
      @include mx.flex_content_center;
      gap: 10px;
    }

    &__right {
      .button {
        margin-left: auto;
      }
    }
  }
}
