@use '../../../mixin' as *;

.folder_drop_zone {
  &__drop {
    height: 5.714em;
    @include flex_direction_column();
    @include flex_all_center();
    background-color: #e0e0e0;
    border-radius: 4px;
    outline: 'none';
    transition: background-color 0.24s ease-in-out;
    gap: 1rem;
    color: #888888;
    position: relative;

    &__wrap {
      padding: 1rem;
    }

    span {
      cursor: default;
      user-select: none;
    }

    label {
      padding: 1px 1.5rem !important;
      cursor: pointer;
      input {
        display: none;
      }
    }

    /* ドロップ可能な場合 */
    &.drag_accept {
      background-color: #dcebf5;
    }

    /* ドロップ拒否の場合 */
    &.drag_reject {
      background-color: #ffe4e1;
    }
  } // .folder_drop_zone__drop

  &__list {
    font-weight: normal;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    padding: 1em 1.75em;
    background-color: #fbfbfb;

    &__thumbs {
      width: 100%;
      @include grid;
      @include grid_repeat_fill($minCol: 16em);
      gap: 1rem;
      .thumbnail {
        width: 100%;
      }
    }

    &__thumb {
      &__label {
        min-width: 5em;
        width: max-content;
        max-width: 100%;
        @include flex_box;
        @include flex_all_center;
        height: 2.5em;
        padding: 0 1em;
        border-radius: 1.25em;
        background-color: #000;
        color: #fff;
        font-weight: bold;
        margin-bottom: 0.5em;
        > span {
          display: inline-block;
          width: 100%;
          text-align: center;
          @include ellipsis;
        }
      }

      .thumbnail {
        // border: 1px solid #e0e0e0;
        &__thumb {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  } // .folder_drop_zone__list
}
